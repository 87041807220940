/** @jsx jsx */
import { Container, Flex, jsx } from 'theme-ui';

export const Layout: React.FC = ({ children }) => (
  <Flex sx={{ flexDirection: 'column', minHeight: '101vh' }}>
    <Container
      px={0}
      pb={6}
      sx={{
        width: '100%',
        flex: '1 1 auto',
      }}
    >
      {children || null}
    </Container>
  </Flex>
);

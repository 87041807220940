/** @jsx jsx */
import { Button as BaseButton, ButtonProps, jsx } from 'theme-ui';

export interface TextButtonProps extends ButtonProps {
  visualSize?: number;
  onClick?: () => void;
  [key: string]: any;
}

export const TextButton: React.FC<TextButtonProps> = ({
  type = 'button',
  visualSize,
  ...props
}) => (
  <BaseButton
    variant='text'
    type={type}
    sx={{
      fontSize: visualSize,
    }}
    {...props}
  />
);

export const NovunaTextButton: React.FC<TextButtonProps> = ({
  type = 'button',
  visualSize,
  ...props
}) => (
  <BaseButton
    variant='text'
    type={type}
    sx={{
      color: 'secondaryPurple',
      fontSize: visualSize,
      border: '0',
      backgroundColor: 'inherit',
      textDecoration: 'underline',
      cursor: 'pointer',
      padding: 0,
      margin: 0,
    }}
    {...props}
  />
);

/* eslint-disable react/prop-types */
/** @jsx jsx */
import React, { useContext } from 'react';
import { jsx, Label, RadioProps, SxStyleProp } from 'theme-ui';
import { FieldSetContext } from '../InputWrapper/InputWrapper';
import { RadioButton } from '../RadioButton';

interface RadioButtonOptionProps extends RadioProps {
  labelSx?: SxStyleProp;
}

export const RadioButtonOption = React.forwardRef<
  HTMLInputElement,
  RadioButtonOptionProps
>(({ labelSx, ...rest }, ref) => {
  const context = useContext(FieldSetContext);

  return (
    <Label mb={3} sx={{ alignItems: 'center', width: 'auto', ...labelSx }}>
      <RadioButton
        {...rest}
        ref={ref}
        {...(context ? { 'aria-describedby': context } : {})}
      />
    </Label>
  );
});

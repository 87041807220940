/** @jsx jsx */
import { alpha } from '@theme-ui/color';
import { Flex, jsx, Text } from 'theme-ui';

interface SummaryRowProps extends React.HTMLAttributes<HTMLDivElement> {
  label: string;
  value: string;
  isWhite?: boolean;
}

export const SummaryRow = ({
  isWhite,
  label,
  value,
  ...props
}: SummaryRowProps) => (
  <Flex
    {...props}
    p={2}
    sx={{
      backgroundColor: isWhite ? 'white' : alpha('background', 0.5),
      justifyContent: 'space-between',
    }}
  >
    <Text
      sx={{
        color: 'monochrome.3',
        '@media screen and (min-width: 320px)': { maxWidth: '160px' },
        '@media screen and (min-width: 420px)': { maxWidth: '50%' },
      }}
    >
      {label}
    </Text>
    <Text
      sx={{
        textAlign: 'right',
        '@media screen and (min-width: 320px)': { maxWidth: '160px' },
        '@media screen and (min-width: 420px)': { maxWidth: '50%' },
      }}
    >
      {value}
    </Text>
  </Flex>
);

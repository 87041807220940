import { Box, Button, Flex, Text, useThemeUI } from 'theme-ui';
import React from 'react';
import { InfoOutline, RadioButtonGroup } from '..';
import { CompassDesignTheme } from '../../themes';

export type TailoringOption =
  | 'term'
  | 'deposit'
  | 'reduceLoan'
  | 'increaseLoan'
  | 'reduceTerm';

export interface PbfRadioOption {
  tailoringOption: TailoringOption;
  text: string;
}
interface TailoringOptionsProps {
  radioOptions: PbfRadioOption[];
  selectedTailoringOption: TailoringOption;
  offerText: string | undefined;
  setTailoringOption: (tailoringOption: TailoringOption) => void;
}

export const TailoringOptions = ({
  radioOptions,
  selectedTailoringOption,
  offerText,
  setTailoringOption,
}: TailoringOptionsProps) => {
  const {
    theme: { channel },
  } = useThemeUI() as CompassDesignTheme;

  const isNovuna = channel === 'NOVUNA';
  const gridColumn = radioOptions.length === 2 ? '1fr 1fr' : '1fr 1fr 1fr';
  const hasThreeButtons = radioOptions.length === 3;

  if (isNovuna) {
    return (
      <Box mb={3} sx={{ display: 'grid', gridTemplateColumns: gridColumn }}>
        {radioOptions.map(({ text, tailoringOption }, i) => {
          const borderRadius = {
            borderTopLeftRadius: i === 0 ? '5px' : '0px',
            borderBottomLeftRadius: i === 0 ? '5px' : '0px',
            borderTopRightRadius: i === radioOptions.length - 1 ? '5px' : '0px',
            borderBottomRightRadius:
              i === radioOptions.length - 1 ? '5px' : '0px',
          };

          const mobileText = text
            .split(' ')
            .filter(
              (word) => !['increase', 'reduce'].includes(word.toLowerCase())
            )
            .join(' ');

          const buttonText = (
            <>
              <Box
                sx={{
                  display: [hasThreeButtons ? 'block' : 'none', null, 'none'],
                }}
              >
                {mobileText[0].toUpperCase() + mobileText.substring(1)}
              </Box>
              <Box
                sx={{
                  display: [hasThreeButtons ? 'none' : 'block', null, 'block'],
                }}
              >
                {text}
              </Box>
            </>
          );

          const borderOverride: any = {};
          if (radioOptions.length === 3) {
            if (selectedTailoringOption === 'term' && i === 2) {
              borderOverride.borderLeft = '0';
            }
            if (selectedTailoringOption === 'reduceLoan' && i === 0) {
              borderOverride.borderRight = '0';
            }
          }

          return (
            <Button
              sx={{
                width: '100%',
                fontWeight: 'bold',
                maxWidth: '310px',
                cursor: 'pointer',
                ...borderRadius,
                ...borderOverride,
              }}
              variant={
                tailoringOption === selectedTailoringOption
                  ? 'radioSelected'
                  : 'radio'
              }
              key={text}
              onClick={() => {
                setTailoringOption(tailoringOption);
              }}
            >
              {buttonText}
            </Button>
          );
        })}
      </Box>
    );
  }

  return (
    <Box my={4}>
      <Text
        mb={3}
        sx={{ fontSize: 1, fontWeight: 'bold' }}
        data-test-id='select-tailoring-text'
      >
        Select an option to update your application:
      </Text>
      <RadioButtonGroup
        name='tailoring-options'
        options={radioOptions.map(({ text, tailoringOption }) => ({
          label: text,
          checked: tailoringOption === selectedTailoringOption,
          value: tailoringOption,
        }))}
        onChange={(e) => {
          setTailoringOption(e.target.value as TailoringOption);
        }}
      />
      <Flex>
        <InfoOutline size={24} color='#737373' />{' '}
        <Text ml={2} sx={{ fontSize: 1, color: '#737373' }}>
          {offerText}
        </Text>
      </Flex>
    </Box>
  );
};

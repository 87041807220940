/** @jsx jsx */
import { css } from 'emotion';
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  Fragment,
} from 'react';
import { createPortal } from 'react-dom';
import { Box, Close, IconButton, jsx, useThemeUI } from 'theme-ui';
import { CompassDesignTheme } from '../../themes/abstractTheme';
import { Heading } from '../Heading';
import { NovunaCross, NovunaHeading } from '../Novuna';

export type ModalProps = {
  title?: string;
  isOpen: boolean;
  onSetIsOpen: (isOpen: boolean) => void;
  id?: string;
  size?: 'small' | 'medium' | 'large';
  printable?: boolean;
  headingSizeOverride?: number;
};

type PortalHolder = {
  element?: HTMLDivElement;
  remove: () => void;
};

// Creates a temporary in the DOM for the Portal to target
function createPortalHolder(id?: string): PortalHolder {
  const element = document.createElement('div');
  element.setAttribute('data-modal-holder', 'true');

  if (id) {
    element.setAttribute('id', id);
  }

  document.body.appendChild(element);

  return {
    element,
    remove() {
      this.element = undefined;
      document.body.removeChild(element);
    },
  };
}

// Emotion never removes global style definitions, so we handle this by
// letting Emotion create the styles, but explicitly adding the resulting
// classes to the root element.
function useGlobalStyle(styles: string) {
  const ref = useRef<string>('');

  const getClassName = useCallback(() => {
    ref.current =
      ref.current ||
      css`
        ${styles}
      `;

    return ref.current;
  }, [styles]);

  const add = useCallback(() => {
    const className = getClassName();
    document.documentElement.classList.add(className);
  }, [getClassName]);

  const remove = useCallback(() => {
    const className = ref.current;
    document.documentElement.classList.remove(className);
  }, []);

  return useMemo(() => ({ add, remove }), [add, remove]);
}

export const Modal: React.FC<ModalProps> = (props) =>
  props.isOpen ? <ModalInner {...props} /> : null;

const ModalInner: React.FC<ModalProps> = ({
  children,
  onSetIsOpen,
  title,
  size = 'small',
  printable = false,
  id,
  headingSizeOverride,
}) => {
  const [domHolder, setDomHolder] = useState({} as PortalHolder);

  const closeModal = useCallback(() => {
    onSetIsOpen(false);
  }, [onSetIsOpen]);

  const keyHandler = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        closeModal();
      }
    },
    [closeModal]
  );

  const panelRef = useRef<HTMLDivElement>(null);

  const clickHandler = useCallback(
    (e: MouseEvent) => {
      if (!panelRef.current?.contains(e.srcElement as Node)) {
        closeModal();
      }
    },
    [closeModal]
  );

  const openStyle = useGlobalStyle(
    `
      height: 100vw;
      overflow: hidden;
    `
  );

  const printableStyle = useGlobalStyle(
    `
    @media print {
      height: auto;
      overflow: auto;

      body > *:not([data-modal-holder]) {
        display: none !important;
      }
    }
  `
  );

  useEffect(() => {
    if (!domHolder.element) {
      openStyle.add();

      if (printable) {
        printableStyle.add();
      }

      setDomHolder(createPortalHolder(id));
      document.addEventListener('keydown', keyHandler);
      document.addEventListener('click', clickHandler);
    }

    return () => {
      if (domHolder.element) {
        domHolder.remove();
        document.removeEventListener('keydown', keyHandler);
        document.removeEventListener('click', clickHandler);

        if (!document.querySelector('[data-modal-holder]')) {
          openStyle.remove();

          if (printable) {
            printableStyle.remove();
          }
        }
      }
    };
  }, [
    domHolder,
    keyHandler,
    printable,
    id,
    openStyle,
    printableStyle,
    clickHandler,
  ]);

  const { theme } = useThemeUI() as CompassDesignTheme;

  const printSx = useCallback(
    (sx: CompassDesignTheme) => {
      if (printable) {
        return {
          '@media print': {
            ...sx,
          },
        };
      }

      return {};
    },
    [printable]
  );

  if (domHolder.element) {
    const {
      channel,
      sizes: {
        modal: {
          headingFontSize: { [size]: headingFontSize },
          width: { [size]: width },
        },
      },
    } = theme as CompassDesignTheme;
    const isNovuna = channel === 'NOVUNA';

    return createPortal(
      <Box
        sx={{
          variant: 'modal.mask',
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 10,
          ...printSx({
            position: 'static',
          }),
        }}
      >
        <Box
          ref={panelRef}
          sx={{
            variant: 'modal.panel',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width,
            ...printSx({
              position: 'static',
              transform: 'none',
              boxShadow: 'none',
            }),
          }}
        >
          <Box sx={{ variant: 'modal.header' }}>
            {title && (
              <Fragment>
                {isNovuna ? (
                  <NovunaHeading as='h2'>{title}</NovunaHeading>
                ) : (
                  <Heading
                    underline={theme.headings?.underline || 'full'}
                    as='h2'
                    visualSize={headingSizeOverride ?? headingFontSize}
                  >
                    {title}
                  </Heading>
                )}
              </Fragment>
            )}
            <Fragment>
              {isNovuna ? (
                <IconButton
                  aria-label='close modal'
                  sx={{
                    variant: 'modal.close',
                    position: 'absolute',
                    cursor: 'pointer',
                  }}
                  onClick={closeModal}
                >
                  <NovunaCross />
                </IconButton>
              ) : (
                <Close
                  aria-label='close modal'
                  sx={{
                    variant: 'modal.close',
                    position: 'absolute',
                    ...printSx({
                      display: 'none',
                    }),
                  }}
                  type='button'
                  onClick={closeModal}
                />
              )}
            </Fragment>
          </Box>
          <Box
            sx={{
              variant: 'modal.content',
              overflowY: 'auto',
              ...printSx({
                maxWidth: 'none',
                maxHeight: 'none',
                overflowY: 'initial',
                paddingBottom: 0,
              }),
            }}
          >
            {children}
          </Box>
        </Box>
      </Box>,
      domHolder.element
    );
  }

  return null;
};

import { merge, Theme } from 'theme-ui';
import { personalLendingTheme } from './personalLendingTheme';
import { darken } from '@theme-ui/color';

export const beSavviTheme = merge(personalLendingTheme, {
  colors: {
    primary: '#157ead', // button blue
    secondary: '#4919b5',
    link: '#157ead', // besavvi blue
    highlight: '#2d2d2d',
    disabledPrimary: '#abc9dd',
    disabledSecondary: '#A58FD6',
  },
  fonts: {
    body: '"Hind", sans-serif',
    heading: '"Varela", sans-serif',
    monospace: 'Menlo, monospace',
  },
  button: {
    fontFamily: 'heading',
    borderRadius: '4px',
  },
  headings: {
    underline: 'none',
    secondary: {
      color: 'secondary',
      fontWeight: 'bold',
      marginTop: 1,
    },
    tertiary: {
      underline: 'none',
      color: 'secondary',
    },
  },
  buttons: {
    primary: {
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: 'primary',
      backgroundColor: 'primary',
      color: 'background',
      '&:hover, &:focus': {
        backgroundColor: darken('primary', 0.08),
        borderColor: darken('primary', 0.08),
      },
      ':disabled': {
        borderColor: 'transparent',
      },
    },
    secondary: {
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: 'primary',
      backgroundColor: 'background',
      color: 'primary',
      '&:hover, &:focus': {
        color: darken('primary', 0.08),
        borderColor: darken('primary', 0.08),
        bg: 'background',
      },
    },
  },
  layout: {
    header: {
      box: {
        borderWidth: '5px 0px 0px',
      },
    },
    footer: {
      minWidth: '100%',
      backgroundColor: 'highlight',
      padding: '2em 0',
      color: 'background',
      a: { color: 'background' },
    },
  },
  cards: {
    primary: {
      borderRadius: '8px',
    },
    secondary: {
      borderRadius: '8px',
    },
  },
  channel: 'BESAVVI',
} as unknown as Theme);

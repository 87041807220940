/** @jsx jsx */
import { FormEvent } from 'react';
import { Box, Flex, Link, jsx } from 'theme-ui';
import { Button } from '../../../';
import { CM3NavBaseProps } from './NavProps';

const MenuLink: React.FC<React.PropsWithChildren<Record<string, unknown>>> = ({
  children,
}) => (
  <Box
    sx={{
      cursor: 'pointer',
      fontWeight: 'heading',
      marginRight: 6,
    }}
  >
    {children}
  </Box>
);

const MainNav = ({
  createNewApplication,
  searchLink,
  usersLink,
}: Pick<
  CM3NavBaseProps,
  'createNewApplication' | 'searchLink' | 'usersLink'
>) => (
  <Flex
    sx={{
      display: ['none', null, null, 'flex'],
      alignItems: 'center',
    }}
  >
    <MenuLink>
      <Link
        sx={{ color: 'text', textDecoration: 'none' }}
        id='cm3-nav-search'
        href={searchLink}
      >
        Search
      </Link>
    </MenuLink>
    {usersLink && (
      <MenuLink>
        <Link
          sx={{ color: 'text', textDecoration: 'none' }}
          id='cm3-nav-users'
          href={usersLink}
        >
          Users
        </Link>
      </MenuLink>
    )}
    <Button
      id='cm3-nav-newapplication'
      mr={5}
      variant='secondary'
      onClick={(event: FormEvent) => {
        event.preventDefault();
        createNewApplication();
      }}
    >
      New application
    </Button>
  </Flex>
);

export default MainNav;

/** @jsx jsx */
import React, { Children } from 'react';
import { Grid, GridProps, jsx, SxStyleProp, useThemeUI } from 'theme-ui';
import { pxToRem } from '../..';
import { CompassDesignTheme } from '../../../themes';

type TableRowProps = GridProps;

export const TableRow = React.forwardRef<HTMLDivElement, TableRowProps>(
  (props, ref) => {
    const {
      theme: { channel },
    } = useThemeUI() as CompassDesignTheme;
    const isNovuna = channel === 'NOVUNA';

    let sx: SxStyleProp = {};
    if (isNovuna) {
      sx = {
        ...sx,
        paddingX: 3,
        paddingY: 4,
        borderBottom: '1px solid',
        borderColor: 'tints.2',
        fontSize: pxToRem([14]),
        ...props.sx,
      };
    } else {
      sx = {
        ...sx,
        paddingTop: 0,
        paddingBottom: 4,
        paddingLeft: 3,
        borderBottom: '2px solid',
        borderColor: 'monochrome.4',
        ...props.sx,
      };
    }

    const { children, onClick } = props;

    if (onClick) {
      sx = {
        ...sx,
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'rgba(113, 110, 145, 0.1)',
        },
      };
    }

    return (
      <Grid
        columns={Children.count(children)}
        ref={ref}
        sx={sx}
        onClick={onClick}
      >
        {children}
      </Grid>
    );
  }
);

/** @jsx jsx */
import {
  Button as BaseButton,
  ButtonProps as BaseButtonProps,
  jsx,
  SxStyleProp,
  useThemeUI,
} from 'theme-ui';

interface GetIsJumbo {
  variant: string;
  isJumbo: boolean;
}

const getIsJumbo = ({ isJumbo, variant }: GetIsJumbo): boolean => {
  if (
    ['secondary', 'secondaryPurple', 'secondaryDestructive'].includes(variant)
  ) {
    return true;
  }
  return isJumbo;
};

interface ButtonProps extends BaseButtonProps {
  isJumbo?: boolean;
  [key: string]: unknown;
}

export const Button: React.FC<ButtonProps> = ({
  type,
  disabled,
  isJumbo = false,
  variant = 'primary',
  ...props
}) => {
  const {
    theme: { buttons },
  } = useThemeUI() as any;

  let novunaSx: SxStyleProp = {};

  if (variant === 'attached') {
    novunaSx = {
      width: ['100%', '100%', '66px', '66px'],
      height: '48px',
      borderRadius: ['5px', '5px', '0 5px 5px 0', '0 5px 5px 0'],
      borderStyle: 'solid',
      borderWidth: '2px',
      borderLeftWidth: ['2px', '2px', '1px', '1px'],
      padding: '12px',
      lineHeight: '.5',
      fontFamily: 'heading',
      fontWeight: 'heading',
    };
  } else if (variant === 'clear') {
    novunaSx = {};
  } else {
    novunaSx = {
      paddingX: '21px',
      paddingY: getIsJumbo({ isJumbo, variant }) ? '14px' : '4px',
      borderColor: (buttons?.[variant] as any)?.borderColor,
      '& svg': {
        display: 'none',
      },
    };
  }

  return (
    <BaseButton
      sx={{
        variant: variant === 'attached' ? undefined : 'button',
        cursor: 'pointer',
        ...novunaSx,
      }}
      {...props}
      type={type ?? 'submit'}
      variant={variant}
      disabled={disabled}
    />
  );
};

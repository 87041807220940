import React from 'react';
import { Box, Text, useThemeUI } from 'theme-ui';
import { NovunaHeading, Message, NovunaSearch } from '..';
import { CompassDesignTheme } from '../../themes/abstractTheme';
import { Heading } from '../Heading';
import { SoftSearchWithTick } from '../Icons';

const SoftSearchHeading = (
  <Heading
    visualSize={3}
    as='h3'
    mb={3}
    mt={3}
    underline='none'
    sx={{ fontWeight: 'normal', display: 'flex' }}
  >
    <Box mr={1} sx={{ color: 'secondary' }}>
      <SoftSearchWithTick ml={0} />
    </Box>{' '}
    Soft search
  </Heading>
);

interface SoftSearchCardInterface {
  text: string;
  displayIcon?: boolean;
}

export const SoftSearchCard = ({
  text,
  displayIcon = true,
}: SoftSearchCardInterface) => {
  const {
    theme: { channel },
  } = useThemeUI() as CompassDesignTheme;

  const isNovuna = channel === 'NOVUNA';

  if (isNovuna) {
    return (
      <Message variant='info' icon={displayIcon ? <NovunaSearch /> : undefined}>
        <NovunaHeading as='h3' mb={1}>
          Soft search
        </NovunaHeading>
        {text}
      </Message>
    );
  }

  return (
    <>
      {SoftSearchHeading}
      <Text mb={6} sx={{ fontSize: 2 }} color='monochrome.3'>
        {text}
      </Text>
    </>
  );
};

interface SoftSearchCardWithExpiryInterface {
  softSearchExpiry?: string;
  displayIcon?: boolean;
}

export const SoftSearchCardWithExpiry = ({
  softSearchExpiry,
  displayIcon = true,
}: SoftSearchCardWithExpiryInterface) => {
  const {
    theme: { channel },
  } = useThemeUI() as CompassDesignTheme;

  const isNovuna = channel === 'NOVUNA';

  const text = <>No footprint was left on the customer&rsquo;s credit file.</>;

  let expiryText;
  if (softSearchExpiry) {
    expiryText = <>This soft search check expires on {softSearchExpiry}.</>;
  }

  if (isNovuna) {
    return (
      <Message variant='info' icon={displayIcon ? <NovunaSearch /> : undefined}>
        <NovunaHeading as='h3' mb={1}>
          Soft search
        </NovunaHeading>
        {expiryText && <>{expiryText} </>}
        {text}
      </Message>
    );
  }

  return (
    <>
      {SoftSearchHeading}
      {expiryText && (
        <Text mb={1} sx={{ fontSize: 2 }} color='monochrome.3'>
          {expiryText}
        </Text>
      )}
      <Text mb={6} sx={{ fontSize: 2 }} color='monochrome.3'>
        {text}
      </Text>
    </>
  );
};

import { merge, Theme } from 'theme-ui';
import { abstractTheme } from './abstractTheme';
import { darken } from '@theme-ui/color';

const columnWidth = 80;
const columnGutter = 24;

const generateColumnWidths = () => {
  const columns = ['0'];
  for (let i = 1; i <= 12; i++) {
    columns[i] = columnWidth * i + columnGutter * (i - 1) + 'px';
  }
  return columns;
};

export const personalLendingTheme = merge(abstractTheme, {
  useCustomProperties: false,
  breakpoints: ['480px', '832px'],
  sizes: {
    pageMin: '360px',
    columns: generateColumnWidths(),
    columnWidth,
    columnGutter,
  },
  layout: {
    header: {
      minWidth: 'pageMin',
      maxWidth: ['columns.8', null, 'columns.12'],
      paddingBottom: ['7px', null, '10px'],
      box: {
        borderWidth: '5px 0 1px',
        borderBottomColor: '#d9d9d9',
        borderStyle: 'solid',
        borderTopColor: 'highlight',
        minWidth: 'pageMin',
      },
    },
    content: {
      minWidth: 'pageMin',
      maxWidth: 'columns.8',
      marginTop: 3,
      paddingLeft: ['3', null, columnWidth + columnGutter + 'px'],
      paddingRight: ['3', null, '0'],
    },
    footer: {
      minWidth: 'pageMin',
      maxWidth: 'columns.8',
      textAlign: 'center',
      fontSize: 0,
      paddingLeft: ['3', null, '0'],
      paddingRight: ['3', null, '0'],
    },
  },
  colors: {
    primary: '#228725',
    secondary: '#228725',
    link: '#007393',
    disabledPrimary: '#a7cfa8',
    disabledSecondary: '#a7cfa8',
  },
  headings: {
    secondary: {
      color: 'text',
      underLineColor: 'highlight',
    },
    tertiary: {
      underLineColor: 'secondary',
    },
  },
  forms: {
    input: {
      minHeight: '48px',
    },
    select: {
      '&::-ms-expand': { display: 'none' },
      minHeight: '48px',
    },
  },
  styles: {
    p: {
      fontSize: 3,
      marginBottom: 4,
      small: {
        fontSize: 1,
        marginBottom: 2,
      },
    },
    a: {
      color: 'link',
      ':visited': darken('link', 0.5),
      '@media print': {
        color: 'text',
        '&[href]:after': {
          content: '" <" attr(href) ">"',
        },
      },
    },
    pre: {
      fontFamily: 'monospace',
      fontSize: '14px',
      color: '#636363',
      overflowX: 'auto',
      padding: 2,
      border: '1px dashed #cecece',
      backgroundColor: '#f2f2f2',
      borderRadius: 'button',
    },
  },
  buttons: {
    primary: {
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: 'primary',
      backgroundColor: 'primary',
      color: 'background',
      '&:hover, &:focus': {
        backgroundColor: darken('primary', 0.08),
        borderColor: darken('primary', 0.08),
      },
      ':disabled': {
        borderColor: 'transparent',
      },
    },
    secondary: {
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: 'primary',
      backgroundColor: 'background',
      color: 'primary',
      '&:hover, &:focus': {
        color: darken('primary', 0.08),
        borderColor: darken('primary', 0.08),
        bg: 'background',
      },
    },
    text: {
      color: 'link',
    },
  },
  channel: 'HPF',
} as unknown as Theme);

/** @jsx jsx */
import { Box, Container, Flex, jsx } from 'theme-ui';
import { layoutPadding } from '../Layout';
import {
  NovunaCM3StackedLogo,
  NovunaPBFHorizontalGreenWhiteLogo,
  NovunaPBFStackedGreenWhiteLogo,
  NovunaPFHorizontalGreenWhiteLogo,
  NovunaPFStackedGreenWhiteLogo,
} from '../Logos';
import { CM3Nav } from './CM3Nav';
import { CM3NavBaseProps } from './CM3Nav/NavProps';
import { UserProfileProps } from './CM3Nav/UserProfile';
import AccessibilityBanner from '../../AccessibilityBanner/AccessibilityBanner';

export interface AccessibilityBannerProps {
  accessibilityBanner?: boolean;
}

export const CM3Header = (
  props: UserProfileProps & CM3NavBaseProps & AccessibilityBannerProps
) => (
  <header sx={{ variant: 'layout.header.creditMaster3' }}>
    <Container
      sx={{
        paddingX: layoutPadding,
        paddingY: 3,
      }}
    >
      <Flex
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: [null, null, 'none'],
            width: 223,
            height: 32,
          }}
        >
          <NovunaCM3StackedLogo />
        </Box>
        <Box
          sx={{
            display: ['none', null, 'block'],
            width: 403,
            height: 67,
          }}
        >
          <NovunaCM3StackedLogo />
        </Box>
        <CM3Nav {...props} />
      </Flex>
    </Container>
    {props.accessibilityBanner && <AccessibilityBanner uiType='center' />}
  </header>
);

export const PBFHeader = (props: AccessibilityBannerProps = {}) => (
  <header sx={{ variant: 'layout.header.payByFinance' }}>
    <Container
      sx={{
        paddingX: layoutPadding,
        paddingTop: [3, null, '29px'],
        paddingBottom: [3, null, '12px'],
      }}
    >
      <Box
        sx={{
          display: [null, null, 'none'],
          width: 223,
          height: 32,
        }}
      >
        <NovunaPBFHorizontalGreenWhiteLogo />
      </Box>
      <Box
        sx={{
          display: ['none', null, 'block'],
          width: 379,
          height: 67,
        }}
      >
        <NovunaPBFStackedGreenWhiteLogo />
      </Box>
    </Container>
    {props.accessibilityBanner && <AccessibilityBanner uiType='center' />}
  </header>
);

export const PFHeader = () => (
  <header sx={{ variant: 'layout.header.personalFinance' }}>
    <Container
      sx={{
        p: layoutPadding,
      }}
    >
      <Box
        sx={{
          display: [null, null, 'none'],
          width: 103,
          height: 32,
        }}
      >
        <NovunaPFStackedGreenWhiteLogo />
      </Box>
      <Box
        sx={{
          display: ['none', null, 'block'],
          width: 379,
          height: 31,
        }}
      >
        <NovunaPFHorizontalGreenWhiteLogo />
      </Box>
    </Container>
  </header>
);

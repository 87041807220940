/** @jsx jsx */
import React, { ReactNode } from 'react';
import { jsx, Flex, InputProps, SxStyleProp } from 'theme-ui';
import { RadioOption } from '../RadioOption';

export interface RadioGroupOption {
  value: string;
  label: ReactNode;
  inputId?: string;
  checked?: boolean;
  labelSx?: SxStyleProp;
}

interface RadioGroupProps extends Omit<InputProps, 'ref'> {
  name: string;
  options: RadioGroupOption[];
}

export const RadioGroup = React.forwardRef<HTMLDivElement, RadioGroupProps>(
  (props, parentRef) => {
    const { name, options, ...rest } = props;

    return (
      <Flex sx={{ flexDirection: 'column' }} ref={parentRef}>
        {options.map(({ label, value, inputId, checked, labelSx }) => (
          <RadioOption
            {...rest}
            id={inputId}
            key={value}
            name={name}
            value={value}
            checked={checked}
            labelSx={labelSx}
          >
            {label}
          </RadioOption>
        ))}
      </Flex>
    );
  }
);

/** @jsx jsx */
import { Box, Flex, jsx, Text, useThemeUI } from 'theme-ui';
import { pxToRem } from '../..';
import { ScreenReaderLabel } from '../../ScreenReaderLabel/ScreenReaderLabel';

export interface AdvancedRadioButtonOption {
  id: string;
  title: string;
  text?: string;
  subText?: string;
  inputId?: string;
  disabled?: boolean;
}

interface AdvancedRadioButtonGroupProps {
  options: AdvancedRadioButtonOption[];
  selectedId?: string;
  twoColumnLayout?: boolean;
  onClick: (id: string) => void;
}

export const AdvancedRadioButtonGroup: React.FC<
  AdvancedRadioButtonGroupProps
> = ({ options, selectedId = '', twoColumnLayout = false, onClick }) => {
  const {
    theme: {
      forms: { radio: radioSx },
    },
  } = useThemeUI() as any;

  const twoColumnSx = twoColumnLayout
    ? {
        display: ['block', null, 'grid'],
        gridTemplateColumns: ['1fr', null, null, '1fr 1fr'],
        gridGap: [0, null, null, 2],
      }
    : {};

  return (
    <Box sx={{ ...twoColumnSx, mb: 4 }}>
      {options.map(
        ({ id, title, text, subText, inputId, disabled = false }) => {
          const selected: boolean = selectedId === id;

          return (
            <Flex
              key={id}
              onClick={() => !disabled && onClick(id)}
              sx={{
                backgroundColor: disabled
                  ? 'form.input.disabled.background'
                  : 'monochrome.7',
                mb: twoColumnLayout ? [2, null, null, 0] : 2,
                padding: 3,
                border: '4px solid',
                borderColor: selected ? 'secondaryPurple' : 'tints.2',
                borderRadius: '10px',
                cursor: disabled ? 'auto' : 'pointer',
                '&:focus': {
                  outline: '2px solid black',
                },
              }}
            >
              <Box sx={{ minWidth: '20px' }}>
                <ScreenReaderLabel id={inputId} label='radio option' />
                <input
                  id={inputId}
                  type='radio'
                  checked={selected}
                  disabled={disabled}
                  readOnly
                  sx={{ ...radioSx }}
                />
              </Box>
              <Box ml='12px' sx={{ flexGrow: 1 }}>
                <Text
                  sx={{
                    color: 'secondaryPurple',
                    fontSize: 3,
                    fontWeight: 'heading',
                    fontFamily: 'heading',
                    mb: 1,
                  }}
                >
                  {title}
                </Text>
                {text && (
                  <Text
                    sx={{
                      color: 'monochrome.3',
                      fontFamily: 'body',
                      mb: 1,
                    }}
                  >
                    {text}
                  </Text>
                )}
                {subText && (
                  <Text
                    sx={{
                      fontSize: pxToRem([14]),
                      color: 'monochrome.1',
                      fontFamily: 'body',
                    }}
                  >
                    {subText}
                  </Text>
                )}
              </Box>
            </Flex>
          );
        }
      )}
    </Box>
  );
};

/** @jsx jsx */
import React, { Fragment } from 'react';
import { List, ListItem } from '../List';
import { jsx, Link, useThemeUI, Text } from 'theme-ui';
import { Heading } from '../Heading';
import { CompassDesignTheme } from '../../themes/abstractTheme';
import { NovunaHeading } from '..';

export const PrivacyPolicyTitle = 'Privacy Policy';

export type HeadingProps = {
  variant?: string;
};

const H: React.FC<HeadingProps> = ({
  children,
  variant = 'secondary',
  ...props
}) => {
  const { theme } = useThemeUI() as CompassDesignTheme;

  const underline = theme.headings?.underline || 'full';

  return (
    <Heading
      as='h2'
      variant={variant}
      visualSize={3}
      underline={underline}
      sx={{ fontWeight: 'bold' }}
      {...props}
    >
      {children}
    </Heading>
  );
};

const LinkItem: React.FC<{ href: string; underlined?: boolean }> = ({
  href,
  underlined,
  children,
}) => (
  <ListItem>
    <Link
      sx={{ textDecoration: underlined ? 'underline' : 'none' }}
      href={href}
      target='_blank'
      rel='noopener noreferrer'
    >
      {children}
    </Link>
  </ListItem>
);

export type PrivacyPolicyContentProps = {
  crainUrl?: string;
  headingVariant?: string;
};

export const PrivacyPolicyContent: React.FC<PrivacyPolicyContentProps> = ({
  crainUrl = 'https://www.hitachicapital.co.uk/crain/',
  headingVariant = 'secondary',
}) => {
  return (
    <Fragment>
      <p>
        The personal information you are providing about yourself and any spouse
        or financial associate will be used by Novuna Personal Finance in the
        following ways:
      </p>

      <H variant={headingVariant}>Information sharing</H>

      <List fontSize='1'>
        <ListItem>
          We will share your information with fraud prevention and Credit
          Reference Agencies (CRAs) including public records and shared credit
          entities
        </ListItem>
      </List>

      <H variant={headingVariant}>Use of information</H>

      <p>We will use your information to:</p>

      <List fontSize='1'>
        <ListItem>Assess your credit worthiness and affordability</ListItem>
        <ListItem>Verify accuracy of the data you have provided</ListItem>
        <ListItem>
          Prevent criminal activity, fraud and money laundering
        </ListItem>
        <ListItem>Manage your account(s)</ListItem>
        <ListItem>Trace and recover debts</ListItem>
        <ListItem>
          Ensure any offers provided are appropriate to your circumstances
        </ListItem>
        <ListItem>Improve our products and services</ListItem>
      </List>

      <H variant={headingVariant}>About Credit Reference Agencies</H>

      <p>Other important information you should be aware of:</p>

      <List fontSize='1'>
        <ListItem>
          We will continue to exchange information about you with CRAs while you
          have a relationship with us
        </ListItem>
        <ListItem>
          If you borrow and do not repay in full and on time, CRAs will record
          the outstanding debt
        </ListItem>
        <ListItem>
          This information may be supplied to other organisations by CRAs
        </ListItem>
        <ListItem>
          CRAs will place a search footprint on your credit file that may be
          seen by other lenders
        </ListItem>
      </List>

      <H variant={headingVariant}>Further information</H>

      <p>
        Further details of how your information will be used by us and these
        credit and fraud prevention agencies, and your data protection rights,
        can be found by visiting these sites:
      </p>

      <List fontSize='1'>
        <LinkItem href={crainUrl}>
          Our Credit Reference Agency Information Notice (CRAIN)
        </LinkItem>
        <LinkItem href='https://www.experian.co.uk/'>Experian</LinkItem>
        <LinkItem href='https://www.transunion.co.uk/'>TransUnion</LinkItem>
        <LinkItem href='https://www.equifax.co.uk/'>Equifax</LinkItem>
        <LinkItem href='https://www.cifas.org.uk/'>Cifas</LinkItem>
      </List>
    </Fragment>
  );
};

export const NovunaPrivacyPolicyContent: React.FC<
  PrivacyPolicyContentProps
> = ({ crainUrl = 'https://www.hitachicapital.co.uk/crain/' }) => (
  <Fragment>
    <Text mb={4}>
      The personal information you are providing about yourself and any spouse
      or financial associate will be used by Novuna Personal Finance in the
      following ways:
    </Text>

    <NovunaHeading as='h3' mb={2}>
      Information sharing
    </NovunaHeading>
    <Text mb={3}>
      We will share your information with Fraud prevention and Credit Reference
      Agencies (CRAs) including public records and shared credit entities.
    </Text>

    <NovunaHeading as='h3' mb={2}>
      Use of information
    </NovunaHeading>
    <Text mb={3}>We will use your information to:</Text>
    <List>
      <ListItem>Assess your credit worthiness and affordability</ListItem>
      <ListItem>Verify accuracy of the data you have provided</ListItem>
      <ListItem>Prevent criminal activity, fraud and money laundering</ListItem>
      <ListItem>Manage your account(s)</ListItem>
      <ListItem>Trace and recover debts</ListItem>
      <ListItem>
        Ensure any offers provided are appropriate to your circumstances
      </ListItem>
      <ListItem>Improve our products and services</ListItem>
    </List>

    <NovunaHeading as='h3' mb={2}>
      About Credit Reference Agencies
    </NovunaHeading>
    <Text mb={3}>Other important information you should be aware of:</Text>
    <List>
      <ListItem>
        We will continue to exchange information about you with CRAs while you
        have a relationship with us
      </ListItem>
      <ListItem>
        If you borrow and do not repay in full and on time, CRAs will record the
        outstanding debt
      </ListItem>
      <ListItem>
        This information may be supplied to other organisations by CRAs
      </ListItem>
      <ListItem>
        CRAs will place a search footprint on your credit file that may be seen
        by other lenders
      </ListItem>
    </List>

    <NovunaHeading as='h3' mb={2}>
      Further information
    </NovunaHeading>
    <Text mb={3}>
      Further details of how your information will be used by us and these
      credit and fraud prevention agencies, and your data protection rights, can
      be found by visiting these sites:
    </Text>
    <List>
      <LinkItem href={crainUrl} underlined>
        Our Credit Reference Agency Information Notice (CRAIN)
      </LinkItem>
      <LinkItem href='https://www.experian.co.uk/' underlined>
        Experian
      </LinkItem>
      <LinkItem href='https://www.transunion.co.uk/' underlined>
        TransUnion
      </LinkItem>
      <LinkItem href='https://www.equifax.co.uk/' underlined>
        Equifax
      </LinkItem>
      <LinkItem href='https://www.cifas.org.uk/' underlined>
        Cifas
      </LinkItem>
    </List>
  </Fragment>
);

/** @jsx jsx */
import React, { ReactNode } from 'react';
import { jsx, Flex, InputProps, SxStyleProp } from 'theme-ui';
import { RadioButtonOption } from '../RadioButtonOption';

export interface RadioButtonGroupOption {
  value: string;
  label: ReactNode;
  checked?: boolean;
  labelSx?: SxStyleProp;
}

interface RadioButtonGroupProps extends Omit<InputProps, 'ref'> {
  name: string;
  options: RadioButtonGroupOption[];
}

export const RadioButtonGroup = React.forwardRef<
  HTMLDivElement,
  RadioButtonGroupProps
>((props, parentRef) => {
  const { name, options, ...rest } = props;

  return (
    <Flex ref={parentRef}>
      {options.map(({ label, value, checked, labelSx }) => (
        <RadioButtonOption
          {...rest}
          key={value}
          name={name}
          value={value}
          checked={checked}
          labelSx={labelSx}
          id={value}
        >
          {label}
        </RadioButtonOption>
      ))}
    </Flex>
  );
});

/** @jsx jsx */
import React from 'react';
import { Box, BoxProps, Flex, jsx } from 'theme-ui';

interface MessageProps extends BoxProps {
  variant: 'info' | 'success' | 'warning' | 'error';
  icon?: React.ReactNode;
  restrictedWidth?: boolean;
  [key: string]: unknown;
}

export const Message: React.FC<MessageProps> = ({
  children,
  variant,
  icon,
  restrictedWidth = true,
  ...props
}) => (
  <Box
    p={3}
    sx={{
      borderRadius: 'panel',
      border: '1px solid',
      maxWidth: restrictedWidth ? '620px' : undefined,
      variant: `message.${variant}`,
    }}
    {...props}
  >
    <Flex>
      {icon && (
        <Box
          sx={{
            display: ['none', null, null, 'block'],
            mr: 4,
            ml: 2,
            flexShrink: 0,
          }}
        >
          {icon}
        </Box>
      )}
      <Box sx={{ width: 'fit-content' }}>{children}</Box>
    </Flex>
  </Box>
);

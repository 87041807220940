type formatIdOptions = {
  separator?: string;
  prefix?: string;
};

export const formatId = (
  unformattedId: string,
  { prefix = '', separator = '-' }: formatIdOptions = {}
) =>
  [
    prefix,
    unformattedId
      .replace(/[\s.]/g, separator)
      .replace(/[='"<>,]/g, '')
      .toLowerCase(),
  ]
    .filter((v) => v)
    .join(separator);

/** @jsx jsx */
import { alpha } from '@theme-ui/color';
import { Fragment } from 'react';
import { Box, jsx, Text } from 'theme-ui';
import { TertiaryLink, TertiaryLinkDirection } from '../..';

interface ProgressBarSectionProps {
  title: string;
  isComplete: boolean;
  isCurrent: boolean;
  onClick: (sectionName: string) => void;
}

const ProgressBarSection = ({
  title,
  isComplete,
  isCurrent,
  onClick,
}: ProgressBarSectionProps) => (
  <Box
    id={`progressbar-section-${title.toLowerCase().replace(' ', '')}`}
    sx={{ mr: 2, cursor: isComplete ? 'pointer' : 'default' }}
    onClick={() => isComplete && onClick(title)}
  >
    <Text
      sx={{
        pt: 2,
        pr: 6,
        pb: 2,
        fontWeight: 600,
        fontFamily: 'heading',
        color:
          isCurrent || isComplete ? 'monochrome.1' : alpha('monochrome.1', 0.4),
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      }}
    >
      {title}
    </Text>
    <Box
      sx={{
        height: '4px',
        borderRadius: '4px',
        backgroundColor: isComplete
          ? 'primary'
          : isCurrent
          ? 'primaryPurple'
          : 'tints.2',
      }}
    />
  </Box>
);

interface ProgressBarProps {
  sections: string[];
  currentSectionIndex: number;
  onClick: (sectionName: string) => void;
}

export const ProgressBar = ({
  sections,
  currentSectionIndex,
  onClick,
}: ProgressBarProps) => (
  <Fragment>
    <Box
      data-test-id='progress-bar-small-screen'
      sx={{ display: ['block', null, null, 'none'] }}
    >
      <TertiaryLink
        direction={TertiaryLinkDirection.BACKWARDS}
        text={sections[currentSectionIndex]}
        onClick={() => onClick(sections[currentSectionIndex])}
      />
    </Box>
    <Box
      data-test-id='progress-bar-large-screen'
      sx={{ display: ['none', null, null, 'flex'] }}
    >
      {sections.map((section, index) => (
        <ProgressBarSection
          key={section}
          title={section}
          isCurrent={index === currentSectionIndex}
          isComplete={index < currentSectionIndex}
          onClick={onClick}
        />
      ))}
    </Box>
  </Fragment>
);

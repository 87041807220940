import { merge, Theme } from 'theme-ui';
import { abstractTheme } from './abstractTheme';

export const cm3Theme = merge(abstractTheme, {
  channel: 'CM3',
  sizes: {
    container: '632px',
  },
  colors: {
    primary: '#b1000e',
    primaryDisabled: '#e0999f',
    secondaryDisabled: '#99c7d4',
  },
  styles: {
    a: {
      // To counteract a global style in CM3
      textDecoration: 'underline',
    },
  },
  button: {
    '&:hover, &:focus': {
      // Override UiKit styles
      color: 'background',
      textDecoration: 'none',
    },
  },
  buttons: {
    primary: {
      '&:disabled, &.disabled': {
        bg: 'primaryDisabled',
      },
    },
    secondary: {
      backgroundColor: 'secondary',
      '&:disabled, &.disabled': {
        bg: 'secondaryDisabled',
      },
    },
  },
} as unknown as Theme);

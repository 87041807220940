/** @jsx jsx */
import { Fragment, useState } from 'react';
import { jsx, Text, Box } from 'theme-ui';
import UserProfile, { UserProfileProps } from './UserProfile';
import { CM3NavBaseProps } from './NavProps';
import SideNav from './SideNav';
import MainNav from './MainNav';

export type CM3NavProps = UserProfileProps & CM3NavBaseProps;

const CM3Nav = ({
  username,
  createNewApplication,
  logoutUser,
  searchLink,
  usersLink,
  managePw,
}: CM3NavProps) => {
  const [menuOpen, handleMenuOpen] = useState(false);

  return (
    <Fragment>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <MainNav
          searchLink={searchLink}
          usersLink={usersLink}
          createNewApplication={createNewApplication}
        />
        <button
          id='cm3-nav-menu'
          type='button'
          sx={{
            display: ['block', null, null, 'none'],
            backgroundColor: 'white',
            border: 0,
            cursor: 'pointer',
            marginRight: 2,
            padding: 0,
          }}
          onClick={() => handleMenuOpen(true)}
        >
          <Text sx={{ fontSize: 1, fontWeight: 'heading' }}>Menu</Text>
        </button>
        {username && (
          <UserProfile username={username} managePw={managePw} logoutUser={logoutUser} />
        )}
      </Box>
      <SideNav
        username={username}
        searchLink={searchLink}
        usersLink={usersLink}
        menuOpen={menuOpen}
        createNewApplication={createNewApplication}
        logoutUser={logoutUser}
        managePw={managePw}
        closeMenu={() => handleMenuOpen(false)}
      />
    </Fragment>
  );
};

export default CM3Nav;

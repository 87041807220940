/* eslint-disable react/prop-types */
import React from 'react';
import { Global, css } from '@emotion/core';

interface GlobalStyleProps {
  lightFontUrl: string;
  regularFontUrl: string;
  boldFontUrl: string;
  fontFamily?: string;
}

export const GlobalStyle: React.FunctionComponent<GlobalStyleProps> = ({
  lightFontUrl,
  regularFontUrl,
  boldFontUrl,
  fontFamily = 'Helvetica Neue',
}) => (
  <Global
    styles={css`
      @font-face {
        font-family: '${fontFamily}';
        src: url(${lightFontUrl}) format('woff');
        font-weight: 100 300;
        font-style: normal;
      }

      @font-face {
        font-family: '${fontFamily}';
        src: url(${regularFontUrl}) format('woff');
        font-weight: 400 500;
        font-style: normal;
      }

      @font-face {
        font-family: '${fontFamily}';
        src: url(${boldFontUrl}) format('woff');
        font-weight: 600 900;
        font-style: normal;
      }
    `}
  />
);

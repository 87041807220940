/** @jsx jsx */
import { FormEvent, Fragment, useRef } from 'react';
import { Box, Flex, jsx, Link, Text } from 'theme-ui';
import { TertiaryLink, TertiaryLinkDirection } from '../../..';
import { WhiteCross } from '../../Icons';
import { NovunaCM3StackedGreenWhiteLogo } from '../../Logos';
import { CM3NavBaseProps } from './NavProps';
import useOutsideCloser from './useOutsideCloser';
import UserProfile from './UserProfile';

const MenuLink: React.FC<React.PropsWithChildren<Record<string, unknown>>> = ({
  children,
}) => (
  <Box
    sx={{
      marginBottom: 4,
      cursor: 'pointer',
      fontWeight: 'heading',
    }}
  >
    {children}
  </Box>
);

export type LinkNavProps = {
  username: string;
  menuOpen: boolean;
  closeMenu: () => void;
} & CM3NavBaseProps;

const SideNav = ({
  username,
  menuOpen,
  searchLink,
  usersLink,
  closeMenu,
  createNewApplication,
  logoutUser,
  managePw,
}: LinkNavProps) => {
  const wrapperRef = useRef(null);
  useOutsideCloser({ ref: wrapperRef, closeMenu });

  return (
    <Box
      ref={wrapperRef}
      sx={{
        display: [menuOpen ? 'block' : 'none', null, null, 'none'],
        height: '100%',
        width: '350px',
        position: 'absolute',
        zIndex: 1,
        top: 0,
        left: 0,
        backgroundColor: 'primaryPurple',
        color: 'white',
        transition: '.5s ease',
        overflowX: 'hidden',
        overflowY: 'auto',
        padding: 4,
      }}
    >
      <Fragment>
        <Flex
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <Flex
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 6,
              }}
            >
              <Box
                sx={{
                  width: 223,
                  height: 32,
                }}
              >
                <NovunaCM3StackedGreenWhiteLogo />
              </Box>
              <Box
                sx={{
                  cursor: 'pointer',
                  paddingTop: 1,
                }}
                onClick={closeMenu}
              >
                <WhiteCross />
              </Box>
            </Flex>

            <Box>
              <MenuLink>
                <Link
                  sx={{ color: 'white', textDecoration: 'none' }}
                  onClick={(event: FormEvent) => {
                    event.preventDefault();
                    createNewApplication();
                  }}
                >
                  New application
                </Link>
              </MenuLink>
              <MenuLink>
                <Link
                  sx={{ color: 'white', textDecoration: 'none' }}
                  href={searchLink}
                >
                  Search
                </Link>
              </MenuLink>
              {usersLink && (
                <MenuLink>
                  <Link
                    sx={{ color: 'white', textDecoration: 'none' }}
                    href={usersLink}
                  >
                    Users
                  </Link>
                </MenuLink>
              )}
            </Box>
          </Box>

          <Box>
            <Box>
              <Flex
                sx={{
                  alignItems: 'center',
                }}
              >
                <UserProfile username={username} managePw={managePw} logoutUser={logoutUser} />
                <Text ml={3} sx={{ color: 'white' }}>
                  {username}
                </Text>
              </Flex>
            </Box>
            {managePw && (
              <Box>
                <TertiaryLink
                  text='Manage password'
                  direction={TertiaryLinkDirection.FORWARDS}
                  onClick={() => {
                    closeMenu();
                    managePw();
                  }}
                  isWhite
                />
              </Box>
            )}
            <Box>
              <TertiaryLink
                text='Sign out'
                direction={TertiaryLinkDirection.FORWARDS}
                onClick={() => {
                  closeMenu();
                  logoutUser();
                }}
                isWhite
              />
            </Box>
          </Box>
        </Flex>
      </Fragment>
    </Box>
  );
};

export default SideNav;

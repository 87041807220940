/** @jsx jsx */
import { alpha } from '@theme-ui/color';
import React from 'react';
import { Box, BoxProps, jsx, SxStyleProp, Text, useThemeUI } from 'theme-ui';
import { NovunaHeading, pxToRem } from '..';
import { CompassDesignTheme } from '../../themes';
import { Button } from '../Button';
import { Card } from '../Card';
import { Heading } from '../Heading';
import { Continue } from '../Icons';
import { ComparisonItem, EditDepositProps } from './ComparisonItem';

interface GetIdPrefix {
  updatedItem?: string;
  isUpdatedOffer: boolean;
}

const getIdPrefix = ({ updatedItem, isUpdatedOffer }: GetIdPrefix) => {
  let idPrefix = 'original';
  if (isUpdatedOffer) {
    idPrefix = `${updatedItem}-updated`;
  }
  return idPrefix;
};

export enum LoanAttribute {
  repaidOver = 'TERM',
  deposit = 'DEPOSIT',
  reduceTotalCost = 'REDUCE_LOAN',
  increaseTotalCost = 'INCREASE_LOAN',
  reduceRepaidOver = 'REDUCE_TERM',
}

const isAffordabilityAcceptTailoringOffer = (
  tailoringType: LoanAttribute
): boolean => {
  const affordabilityAcceptTailoringOffers = [
    LoanAttribute.increaseTotalCost,
    LoanAttribute.reduceRepaidOver,
  ];
  return affordabilityAcceptTailoringOffers.includes(tailoringType)
    ? true
    : false;
};

const isTermAlteringTailoringOffer = (
  tailoringType: LoanAttribute
): boolean => {
  const termAlteringTailoringOffers = [
    LoanAttribute.repaidOver,
    LoanAttribute.reduceRepaidOver,
  ];
  return termAlteringTailoringOffers.includes(tailoringType) ? true : false;
};

const ApplicationTitleCss = (
  <Box
    sx={{
      height: '0',
      '@media screen and (min-width: 526px)': {
        height: '26px',
      },
      '@media screen and (min-width: 538px)': {
        height: '0',
      },
      '@media screen and (min-width: 640px)': {
        height: '26px',
      },
      '@media screen and (min-width: 650px)': {
        height: '0',
      },
      '@media screen and (min-width: 1074px)': {
        height: '26px',
      },
      '@media screen and (min-width: 1086px)': {
        height: '0',
      },
    }}
  />
);

const representativeExample = (isUpdatedOffer: boolean) => {
  if (isUpdatedOffer) {
    return <Text sx={{ fontSize: pxToRem([14]) }}>Representative example</Text>;
  }

  return (
    <Box
      sx={{
        height: '42px',
        '@media screen and (min-width: 438px)': {
          height: '21px',
        },
        '@media screen and (min-width: 486px)': {
          height: '21px',
        },
        '@media screen and (min-width: 960px)': {
          height: '42px',
        },
        '@media screen and (min-width: 1018px)': {
          height: '21px',
        },
      }}
    />
  );
};

const totalCashPrice = (isUpdatedOffer: boolean, isNovuna: boolean) => {
  if (isUpdatedOffer) {
    return (
      <Text mt={1} sx={{ fontSize: 0 }}>
        Total cash price of goods or services
      </Text>
    );
  }

  if (!isNovuna) {
    return (
      <Box
        mt={1}
        sx={{
          '@media screen and (min-width: 556px)': {
            pt: '14px',
          },
        }}
      />
    );
  }

  return (
    <Box
      mt={1}
      sx={{
        pt: '36px',
        '@media screen and (min-width: 557px)': {
          pt: '19px',
        },
        '@media screen and (min-width: 640px)': {
          pt: '36px',
        },
        '@media screen and (min-width: 669px)': {
          pt: '19px',
        },
        '@media screen and (min-width: 960px)': {
          pt: '36px',
        },
        '@media screen and (min-width: 1105px)': {
          pt: '19px',
        },
      }}
    />
  );
};

const RepaidOverCss = (
  <Box
    sx={{
      height: '41px',
      '@media screen and (min-width: 346px)': {
        height: '25px',
      },
      '@media screen and (min-width: 414px)': {
        height: '0',
      },
      '@media screen and (min-width: 960px)': {
        height: '25px',
      },
      '@media screen and (min-width: 994px)': {
        height: '0',
      },
    }}
  />
);

const EditingDepositErrorCss = (
  <Box
    sx={{
      height: '78px',
      '@media screen and (min-width: 439px)': {
        height: '52px',
      },
      '@media screen and (min-width: 622px)': {
        height: '35px',
      },
      '@media screen and (min-width: 606px)': {
        height: '52px',
      },
      '@media screen and (min-width: 750px)': {
        height: '35px',
      },
      '@media screen and (min-width: 960px)': {
        height: '78px',
      },
      '@media screen and (min-width: 1051px)': {
        height: '52px',
      },
      '@media screen and (min-width: 1186px)': {
        height: '35px',
      },
    }}
  />
);

export interface LoanComparisonCardProps extends BoxProps {
  totalCost: string;
  deposit: string;
  amountBorrowed: string;
  repaidOver: string;
  interestRate: string;
  representativeAPR: string;
  interestPayable: string;
  totalAmountPayable: string;
  monthlyRepayment: string;
  updatedItem: LoanAttribute;
  isSoftSearch?: boolean;
  disabled?: boolean;
  showPlaceholders?: boolean;
  editDeposit?: EditDepositProps;
  depositError?: string;
  isUpdatedOffer?: boolean;
  buttonOnClick?: () => void;
  hideApplicationText?: boolean;
  isPbfTailoring?: boolean;
  isDesktopView?: boolean;
  interestPayableUpdated?: boolean;
  totalAmountPayableUpdated?: boolean;
  [key: string]: unknown;
}

export const LoanComparisonCard = React.forwardRef<
  HTMLDivElement,
  LoanComparisonCardProps
>(
  (
    {
      totalCost,
      deposit,
      amountBorrowed,
      repaidOver,
      interestRate,
      representativeAPR,
      interestPayable,
      totalAmountPayable,
      monthlyRepayment,
      updatedItem,
      isSoftSearch,
      disabled = false,
      showPlaceholders,
      editDeposit,
      depositError,
      isUpdatedOffer,
      buttonOnClick,
      sx,
      hideApplicationText,
      isPbfTailoring,
      isDesktopView,
      interestPayableUpdated,
      totalAmountPayableUpdated,
      ...rest
    },
    ref
  ) => {
    const {
      theme: { channel },
    } = useThemeUI() as CompassDesignTheme;

    const isNovuna = channel === 'NOVUNA';

    const fieldsWithPlaceholders = showPlaceholders
      ? [
          'Total cost',
          'Amount borrowed',
          'Interest payable',
          'Total payable',
          'Monthly repayment',
        ]
      : isUpdatedOffer && (editDeposit?.errorMessage || depositError)
      ? [
          'Amount borrowed',
          'Interest payable',
          'Total payable',
          'Monthly repayment',
        ]
      : [];

    const idPrefix = getIdPrefix({ updatedItem, isUpdatedOffer });
    const buttonText = isSoftSearch ? 'Apply' : 'Continue';

    const showContinueButton = isNovuna || !isPbfTailoring || isDesktopView;

    const buttonSx = isNovuna
      ? {
          display: [disabled ? 'none' : 'block', 'block'],
        }
      : {};

    const novunaSx: SxStyleProp = isNovuna
      ? {
          backgroundColor: disabled ? alpha('background', 0.5) : 'monochrome.7',
          // Override
          boxShadow: 'none',
          flexGrow: 0,
        }
      : {};

    let headingMarginBottom = isUpdatedOffer ? 1 : ['40px', '22px', '22px'];

    if (isNovuna) {
      headingMarginBottom = 1;
    }

    const cardBorder = isPbfTailoring ? 20 : [0, 'panel', 'panel'];

    return (
      <Card
        ref={ref}
        variant={disabled ? 'disabled' : 'secondary'}
        px={[3, 4, 4]}
        py={3}
        mx={isNovuna ? undefined : [-3, 0, 0]}
        sx={{
          flexGrow: 1,
          borderRadius: isNovuna ? '5px' : cardBorder,
          boxShadow: !disabled
            ? [isPbfTailoring ? 'panel' : 'none', 'panel', 'panel']
            : undefined,
          maxWidth: [undefined, isNovuna ? undefined : '291px'],
          ...novunaSx,
          ...sx,
        }}
        {...rest}
      >
        {isNovuna ? (
          <NovunaHeading as='h3' mb={1}>
            {isUpdatedOffer ? 'Updated' : 'Original'}{' '}
            {hideApplicationText ? '' : 'application'}
            {!isUpdatedOffer && !hideApplicationText && ApplicationTitleCss}
          </NovunaHeading>
        ) : (
          <Heading
            visualSize={2}
            underline='none'
            sx={{
              fontWeight: 'bold',
              marginBottom: headingMarginBottom,
            }}
            color={disabled ? 'monochrome.3' : 'text'}
          >
            {isUpdatedOffer ? 'Updated' : 'Original'}{' '}
            {hideApplicationText ? '' : 'application'}
          </Heading>
        )}

        {isNovuna && representativeExample(!!isUpdatedOffer)}
        {!isNovuna && isUpdatedOffer && (
          <Heading
            visualSize={1}
            underline='none'
            color={disabled ? 'monochrome.3' : 'text'}
          >
            Representative example
          </Heading>
        )}

        <ComparisonItem
          label='Total cost'
          idPrefix={idPrefix}
          showPlaceholder={fieldsWithPlaceholders.includes('Total cost')}
          widePlaceholder={true}
          updatedItem={
            isUpdatedOffer &&
            (updatedItem === LoanAttribute.reduceTotalCost ||
              updatedItem === LoanAttribute.increaseTotalCost)
          }
          updatedItemSx={
            !isUpdatedOffer && updatedItem === LoanAttribute.reduceTotalCost
              ? 'line-through'
              : undefined
          }
          isPbfTailoring={isPbfTailoring}
          disabled={disabled}
        >
          {totalCost}
        </ComparisonItem>
        {totalCashPrice(!!isUpdatedOffer, isNovuna)}
        <ComparisonItem
          label='Deposit'
          idPrefix={idPrefix}
          updatedItem={isUpdatedOffer && updatedItem === LoanAttribute.deposit}
          editDeposit={
            isUpdatedOffer &&
            (updatedItem === LoanAttribute.reduceTotalCost ||
              updatedItem === LoanAttribute.increaseTotalCost) &&
            editDeposit
              ? editDeposit
              : undefined
          }
          depositError={
            depositError && isUpdatedOffer ? depositError : undefined
          }
          updatedItemSx={
            !isUpdatedOffer && updatedItem === LoanAttribute.deposit
              ? 'line-through'
              : undefined
          }
          isPbfTailoring={isPbfTailoring}
          disabled={disabled}
        >
          {deposit}
        </ComparisonItem>
        {!isPbfTailoring && updatedItem === LoanAttribute.deposit ? (
          !isUpdatedOffer ? (
            // Spacing to match corresponding text below
            <Box mt={1} pt={[0, '14px', '14px']} />
          ) : (
            <Text mt={1} sx={{ fontSize: 0 }}>
              Cash or debit card accepted
            </Text>
          )
        ) : (
          // Spacing for when input field is in use
          (updatedItem === LoanAttribute.reduceTotalCost ||
            updatedItem === LoanAttribute.increaseTotalCost) &&
          !isUpdatedOffer && (
            <Box>
              {editDeposit?.editing && (
                <Box
                  sx={{
                    height: '50px',
                  }}
                />
              )}
              {(depositError || editDeposit?.errorMessage) &&
                EditingDepositErrorCss}
            </Box>
          )
        )}

        <ComparisonItem
          label='Amount borrowed'
          idPrefix={idPrefix}
          showPlaceholder={fieldsWithPlaceholders.includes('Amount borrowed')}
          widePlaceholder={true}
          isPbfTailoring={isPbfTailoring}
          updatedItem={
            isUpdatedOffer && !isTermAlteringTailoringOffer(updatedItem)
          }
          updatedItemSx={
            !isUpdatedOffer &&
            !isTermAlteringTailoringOffer(updatedItem) &&
            !isAffordabilityAcceptTailoringOffer(updatedItem)
              ? 'line-through'
              : undefined
          }
          disabled={disabled}
        >
          {amountBorrowed}
        </ComparisonItem>
        <ComparisonItem
          label='Repaid over'
          idPrefix={idPrefix}
          updatedItem={
            isUpdatedOffer && isTermAlteringTailoringOffer(updatedItem)
          }
          updatedItemSx={
            !isUpdatedOffer && updatedItem === LoanAttribute.repaidOver
              ? 'line-through'
              : undefined
          }
          isPbfTailoring={isPbfTailoring}
          isRepaidOver={true}
          disabled={disabled}
        >
          {repaidOver}
        </ComparisonItem>
        {!isUpdatedOffer &&
          isTermAlteringTailoringOffer(updatedItem) &&
          RepaidOverCss}
        <ComparisonItem
          label='Interest rate (fixed)'
          idPrefix={idPrefix}
          isPbfTailoring={isPbfTailoring}
          disabled={disabled}
        >
          {interestRate}
        </ComparisonItem>
        <ComparisonItem
          label='Representative APR'
          idPrefix={idPrefix}
          isPbfTailoring={isPbfTailoring}
          disabled={disabled}
        >
          {representativeAPR} APR
        </ComparisonItem>
        <ComparisonItem
          label='Interest payable'
          idPrefix={idPrefix}
          showPlaceholder={fieldsWithPlaceholders.includes('Interest payable')}
          isPbfTailoring={isPbfTailoring}
          updatedItem={isUpdatedOffer && interestPayableUpdated}
          updatedItemSx={
            !isUpdatedOffer &&
            interestPayableUpdated &&
            !isAffordabilityAcceptTailoringOffer(updatedItem)
              ? 'line-through'
              : undefined
          }
          disabled={disabled}
        >
          {interestPayable}
        </ComparisonItem>
        <ComparisonItem
          label='Total payable'
          idPrefix={idPrefix}
          showPlaceholder={fieldsWithPlaceholders.includes('Total payable')}
          widePlaceholder={true}
          isPbfTailoring={isPbfTailoring}
          updatedItem={isUpdatedOffer && totalAmountPayableUpdated}
          updatedItemSx={
            !isUpdatedOffer &&
            totalAmountPayableUpdated &&
            !isAffordabilityAcceptTailoringOffer(updatedItem)
              ? 'line-through'
              : undefined
          }
          disabled={disabled}
        >
          {totalAmountPayable}
        </ComparisonItem>
        <ComparisonItem
          label='Monthly repayment'
          idPrefix={idPrefix}
          showPlaceholder={fieldsWithPlaceholders.includes('Monthly repayment')}
          updatedItem={isUpdatedOffer}
          updatedItemSx={
            !isUpdatedOffer && !isAffordabilityAcceptTailoringOffer(updatedItem)
              ? 'line-through'
              : undefined
          }
          isPbfTailoring={isPbfTailoring}
          disabled={disabled}
        >
          {monthlyRepayment}
        </ComparisonItem>
        {showContinueButton && (
          <Button
            sx={buttonSx}
            isJumbo
            data-test-id={
              isUpdatedOffer
                ? 'updated-pbf-mobile-view-continue-button'
                : 'original-pbf-mobile-view-continue-button'
            }
            mt={5}
            disabled={disabled || fieldsWithPlaceholders.length !== 0}
            onClick={buttonOnClick}
          >
            {disabled ? 'Unavailable' : buttonText}
            <Continue />
          </Button>
        )}
      </Card>
    );
  }
);

/** @jsx jsx */
import { jsx } from 'theme-ui';
import { ReactComponent as ArrowRightSvg } from './novuna-arrow-right.svg';
import { ReactComponent as NovunaArrowDownSvg } from './novuna-arrow-down.svg';
import { ReactComponent as NovunaArrowUpSvg } from './purple-arrow-up.svg';
import { ReactComponent as WhiteArrowRightSvg } from './white-arrow-right.svg';
import { ReactComponent as WhiteArrowLeftSvg } from './novuna-arrow-left.svg';
import { ReactComponent as WhiteCrossSvg } from './cross-white.svg';
import { ReactComponent as RedAlertSvg } from './small-icon-alert-red.svg';
import { ReactComponent as PurpleAlertSvg } from './icon-alert-purple.svg';
import { ReactComponent as PurpleMailSvg } from './icon-mail-purple.svg';
import { ReactComponent as PurpleSecuritySvg } from './icon-security-purple.svg';
import { ReactComponent as SecuritySvg } from './icon-lock-purple.svg';
import { ReactComponent as NovunaCheckboxTickSvg } from './small-icon-check-grey-dark.svg';
import { ReactComponent as NovunaCrossSvg } from './small-icon-cross-purple-dark.svg';
import { ReactComponent as NovunaTickSvg } from './icon-tick-purple.svg';
import { ReactComponent as NovunaDocumentSvg } from './icon-document-purple.svg';
import { ReactComponent as NovunaPlusSvg } from './small-icon-plus-purple-dark.svg';
import { ReactComponent as NovunaMinusSvg } from './small-icon-minus-purple-dark.svg';
import { ReactComponent as NovunaSearchSvg } from './icon-search-purple.svg';
import { ReactComponent as NovunaHomeSvg } from './icon-home-purple.svg';

export const NovunaArrowRight = () => (
  <ArrowRightSvg
    width={24}
    height={24}
    sx={{
      fill: 'currentColor',
    }}
    aria-label='right arrow'
  />
);

export const NovunaArrowLeft = () => (
  <WhiteArrowLeftSvg
    sx={{
      fill: 'currentColor',
    }}
    aria-label='left arrow'
  />
);

export const NovunaArrowDown = () => (
  <NovunaArrowDownSvg
    width={24}
    height={24}
    sx={{
      fill: 'currentColor',
    }}
  />
);

export const NovunaArrowUp = () => (
  <NovunaArrowUpSvg
    width={24}
    height={24}
    sx={{
      fill: 'currentColor',
    }}
  />
);

export const WhiteArrowRight = () => (
  <WhiteArrowRightSvg
    width={28}
    height={28}
    sx={{
      fill: '#fff',
    }}
  />
);

export const WhiteCross = () => (
  <WhiteCrossSvg
    width={28}
    height={28}
    sx={{
      fill: 'currentColor',
    }}
  />
);

export const RedAlert = () => (
  <RedAlertSvg
    sx={{
      fill: 'currentColor',
    }}
  />
);

export const PurpleAlert = () => (
  <PurpleAlertSvg
    sx={{
      fill: 'currentColor',
    }}
  />
);

export const PurpleMail = () => (
  <PurpleMailSvg
    width={64}
    height={64}
    sx={{
      fill: 'currentColor',
    }}
  />
);

export const PurpleSecurity = () => (
  <PurpleSecuritySvg
    width={64}
    height={64}
    sx={{
      fill: 'currentColor',
    }}
  />
);

export const NovunaCheckboxTick = () => (
  <NovunaCheckboxTickSvg
    sx={{
      fill: 'currentColor',
    }}
  />
);

export const NovunaCross = () => (
  <NovunaCrossSvg
    sx={{
      fill: 'currentColor',
    }}
  />
);

export const NovunaTick = () => (
  <NovunaTickSvg
    sx={{
      fill: 'currentColor',
    }}
  />
);

export const NovunaDocument = () => (
  <NovunaDocumentSvg
    sx={{
      fill: 'currentColor',
    }}
  />
);

export const NovunaSearch = () => (
  <NovunaSearchSvg
    sx={{
      fill: 'currentColor',
    }}
  />
);

export const NovunaHome = () => (
  <NovunaHomeSvg
    sx={{
      fill: 'currentColor',
    }}
  />
);

export const NovunaPlus = () => (
  <NovunaPlusSvg
    sx={{
      fill: 'currentColor',
    }}
  />
);

export const NovunaMinus = () => (
  <NovunaMinusSvg
    sx={{
      fill: 'currentColor',
    }}
  />
);

export const Security = () => (
  <SecuritySvg
    sx={{
      fill: 'currentColor',
    }}
  />
);

/** @jsx jsx */
import React, { createContext, useContext } from 'react';
import { Box, Flex, jsx, SxStyleProp, Text, useThemeUI } from 'theme-ui';
import { CompassDesignTheme } from '../../themes';
import ArrowRight from '../Icons/ArrowRight.svg';
import NovunaArrowRight from '../Novuna/Icons/small-icon-chevron-small-purple-dark.svg';

interface ListProps {
  fontSize?: string;
  ordered?: boolean;
  nested?: boolean;
  [key: string]: unknown;
}

type ListContext = {
  ordered: ListProps['ordered'];
};
const Context = createContext<ListContext>({
  ordered: false,
});

export const List: React.FC<ListProps> = React.forwardRef<
  HTMLOListElement,
  ListProps
>(({ fontSize = '3', ordered = false, nested, children, ...rest }, ref) => {
  const nestedProps = nested ? { paddingTop: '1', marginTop: '2' } : {};
  const childSx = {
    '& > li': {
      fontSize,
    },
  };

  const ListTag = ordered ? 'ol' : 'ul';

  return (
    <Context.Provider value={{ ordered }}>
      <ListTag
        sx={{
          backgroundPosition: 'left 4px',
          paddingLeft: ordered ? undefined : 0,
          ...childSx,
          ...nestedProps,
        }}
        {...rest}
        ref={ref}
      >
        {children || null}
      </ListTag>
    </Context.Provider>
  );
});

interface ListItemProps {
  useHyphen?: boolean;
  [key: string]: unknown;
}

export const ListItem: React.FC<ListItemProps> = React.forwardRef<
  HTMLLIElement,
  ListItemProps
>(({ useHyphen, children, ...rest }, ref) => {
  const { ordered } = useContext(Context);
  const {
    theme: { channel },
  } = useThemeUI() as CompassDesignTheme;

  const isNovuna = channel === 'NOVUNA';

  let itemProps: SxStyleProp = {
    listStyleType: ordered ? 'decimal' : 'none',
    paddingBottom: isNovuna ? 0 : 1,
    marginBottom: 2,
    ...(ordered
      ? {
          '&::marker': {
            fontFamily: 'heading',
            fontWeight: 'bold',
          },
        }
      : {}),
  };

  // Default bullet uses the arrow > icon
  if (!useHyphen && !ordered) {
    itemProps = {
      ...itemProps,
      paddingLeft: isNovuna ? '1.9rem' : '1.5rem',
      backgroundImage: `url(${isNovuna ? NovunaArrowRight : ArrowRight})`,
      backgroundRepeat: 'no-repeat',
      backgroundColor: 'transparent',
      backgroundPosition: isNovuna ? '0 0' : `top calc(0.6em - 0.7rem) left`,
      backgroundSize: '1.5rem 1.5rem',
    };
  }

  return (
    <li sx={{ ...itemProps }} {...rest} ref={ref}>
      <Flex>
        {/* Set up the hyphen - icon */}
        {useHyphen ? (
          <Box sx={{ minWidth: '24px', paddingLeft: '8px' }}>
            <Text>-</Text>
          </Box>
        ) : (
          ''
        )}
        <Box>{children || null}</Box>
      </Flex>
    </li>
  );
});

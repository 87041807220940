import { useReducer } from 'react';

export function useIsModalOpen(
  initially = false
): [boolean, React.Dispatch<unknown>] {
  const [modalIsOpen, toggleModalIsOpen] = useReducer(
    (isOpen = false) => !isOpen,
    initially
  );

  return [modalIsOpen, toggleModalIsOpen];
}

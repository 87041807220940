/* eslint-disable react/prop-types */
/** @jsx jsx */
import React from 'react';
import {
  Box,
  Heading as BaseHeading,
  HeadingProps as BaseHeadingProps,
  jsx,
  Theme,
  useThemeUI,
} from 'theme-ui';
import { Skeleton } from '../Skeleton';
import { CompassDesignTheme } from '../../themes/abstractTheme';

export interface HeadingProps extends BaseHeadingProps {
  underline?: 'full' | 'partial' | 'none'; // defaults to full
  visualSize: 1 | 2 | 3 | 4 | 5 | 6;
  skeleton?: boolean;
  underlineMargin?: string | number;
  [key: string]: unknown;
}

export const Heading: React.FunctionComponent<HeadingProps> = React.forwardRef<
  HTMLHeadingElement,
  HeadingProps
>((props, ref) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { theme } = useThemeUI() as CompassDesignTheme;
  const underlineWidth = { full: '100%', partial: '2em' };
  const {
    sx,
    variant = 'primary',
    visualSize,
    skeleton,
    underlineMargin,
    ...rest
  } = props;
  const underline = props.underline || theme.headings?.underline || 'full';

  const colorMap = {
    primary: 'highlight',
    secondary: 'secondary',
  };

  let underlineStyle;
  if (underline != 'none') {
    underlineStyle = {
      '::after': {
        content: '""',
        width: underlineWidth[underline],
        height: 0,
        border: '0 solid transparent',
        borderTopWidth: visualSize === 5 ? '4px' : '2px',
        display: 'block',
        borderTopColor: (theme: any) =>
          theme.headings[variant]?.underLineColor ?? colorMap[variant],
        marginTop: (theme: any) =>
          underlineMargin ?? theme.headings.underlineMargin,
      },
    };
  }

  const properties = {
    ...rest,
    sx: {
      fontSize: visualSize,
      ...underlineStyle,
      ...sx,
      ...(theme.headings && theme.headings[variant]
        ? theme.headings[variant]
        : {}),
    },
  };

  return (
    (!skeleton && (
      <Box sx={{ display: 'table' }}>
        <BaseHeading {...properties} ref={ref} />
      </Box>
    )) || (
      <Box
        sx={{
          fontSize: (theme: Theme) =>
            `calc(${theme?.fontSizes?.[visualSize]} + 1em)`,
          maxWidth: '12em',
        }}
      >
        <Skeleton />
      </Box>
    )
  );
});

/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/** @jsx jsx */
import React, { useContext } from 'react';
import {
  Flex,
  jsx,
  Text,
  Textarea,
  TextareaProps as BaseTextareaProps,
} from 'theme-ui';
import { FieldSetContext } from '../InputWrapper/InputWrapper';
import { mapToneBackgroundColor, mapToneColor, Tone } from '../tones';

interface TextAreaProps extends BaseTextareaProps {
  tone?: Tone;
  description?: string;
  flexSx?: object;
  textareaSx?: object;
}

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      tone = 'neutral',
      description,
      flexSx,
      textareaSx,
      id,
      name,
      value,
      rows,
      ...rest
    },
    ref
  ) => {
    const context = useContext(FieldSetContext);

    return (
      <Flex
        sx={{
          flexDirection: 'column',
          ...flexSx,
        }}
      >
        {description && (
          <Text mb={2} sx={{ color: 'monochrome.3' }}>
            {description}
          </Text>
        )}
        <Textarea
          {...rest}
          value={value}
          ref={ref}
          id={id ?? name}
          name={name}
          {...(context ? { 'aria-describedby': context } : {})}
          rows={rows}
          sx={{
            borderColor: mapToneColor(tone),
            backgroundColor: mapToneBackgroundColor(tone),
            resize: 'none',
            ...textareaSx,
          }}
        />
      </Flex>
    );
  }
);

/** @jsx jsx */
import React, { Fragment } from 'react';
import { Box, jsx, Styled, useThemeUI } from 'theme-ui';
import { Card } from '../Card';
import { Heading } from '../Heading';
import { Button } from '../Button';
import { LaunchCircle } from '../Icons';
import { useIsModalOpen, Modal } from '../Modal';
import {
  PrivacyPolicyTitle,
  PrivacyPolicyContent,
  PrivacyPolicyContentProps,
  NovunaPrivacyPolicyContent,
} from './PrivacyPolicyContent';
import { CompassDesignTheme } from '../../themes';
import { Message, NovunaHeading, ReactModal, Security } from '..';

type PrivacyPolicyCardProps = PrivacyPolicyContentProps & {
  fullPolicyUrl?: string;
  headingVariant?: string;
  novunaOverride?: boolean;
  isHardSearch?: boolean;
};

export const PrivacyPolicyCard: React.FC<PrivacyPolicyCardProps> = ({
  fullPolicyUrl,
  crainUrl,
  novunaOverride,
  headingVariant = 'secondary',
  isHardSearch = true,
}) => {
  const {
    theme: { headings, channel },
  } = useThemeUI() as CompassDesignTheme;

  const isNovuna = novunaOverride ? novunaOverride : channel === 'NOVUNA';

  fullPolicyUrl = isNovuna
    ? 'https://www.novunapersonalfinance.co.uk/privacy-policy'
    : 'https://www.hitachicapital.co.uk/privacy-policy/';

  const [modalIsOpen, toggleModalIsOpen] = useIsModalOpen();

  const underline = headings[headingVariant]?.underline || 'full';

  const headingText = 'Your data and privacy when applying for a loan';

  const Text = (
    <Fragment>
      <p>
        We will share your information with fraud prevention and Credit
        Reference Agencies (CRAs) including public records and shared credit
        organisations.
      </p>
      {isHardSearch && (
        <p>
          When you submit your application we’ll perform a credit check which
          will leave a record on your credit file.
        </p>
      )}
      <p>
        Further information about the impact of us using and sharing your data
        is available here:
      </p>
    </Fragment>
  );

  const PolicyLink = (
    <p>
      By continuing you are agreeing to the terms of our{' '}
      <Styled.a
        sx={{ textDecoration: 'underline' }}
        href={fullPolicyUrl}
        target='_blank'
        rel='noopener noreferrer'
      >
        Privacy Policy.
      </Styled.a>
    </p>
  );

  if (isNovuna) {
    return (
      <Message variant='info' icon={<Security />}>
        <Box>
          <NovunaHeading as='h3'>{headingText}</NovunaHeading>
          {Text}
          <Button variant='secondary' onClick={toggleModalIsOpen}>
            Key information
          </Button>
          {PolicyLink}
        </Box>
        <ReactModal
          size='medium'
          title={PrivacyPolicyTitle}
          isOpen={modalIsOpen}
          setIsOpen={toggleModalIsOpen}
        >
          <NovunaPrivacyPolicyContent
            crainUrl={
              crainUrl ?? 'https://www.novunapersonalfinance.co.uk/crain/'
            }
          />
        </ReactModal>
      </Message>
    );
  }

  return (
    <Card variant='secondary' p={4} my={3}>
      <Heading
        as='h2'
        variant={headingVariant}
        visualSize={3}
        underline={underline}
        sx={{
          fontWeight: 'bold',
          ...(headings[headingVariant] || {}),
        }}
      >
        {headingText}
      </Heading>
      {Text}
      <Button variant='secondary' onClick={toggleModalIsOpen}>
        Key information <LaunchCircle />
      </Button>
      {PolicyLink}

      <Modal
        size='medium'
        title={PrivacyPolicyTitle}
        isOpen={modalIsOpen}
        onSetIsOpen={toggleModalIsOpen}
        printable
      >
        <PrivacyPolicyContent
          crainUrl={crainUrl}
          headingVariant={headingVariant}
        />
      </Modal>
    </Card>
  );
};

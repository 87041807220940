import React from 'react';
import { Badge, Box, Flex, Text, useThemeUI } from 'theme-ui';
import { CompassDesignTheme } from '../../themes';
import { TextField } from '../Input';
import { Placeholder } from '../Placeholder';
import { SectionError } from '../SectionError';
import { NovunaTextButton, TextButton } from '../TextButton';

const formatId = (unformattedId: string): string =>
  unformattedId.toLowerCase().split(' ').join('-');

export interface EditDepositProps {
  editing: boolean;
  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
  value: string;
  errorMessage?: string;
  onChange: (depositValue: string) => void;
  minDepositPercentage: string;
}

const EditDeposit: React.FC<
  EditDepositProps & { id: string; isNovuna: boolean }
> = ({
  id,
  editing,
  setEditing,
  value, // unformatted deposit
  errorMessage,
  onChange,
  minDepositPercentage,
  children, // formatted deposit,
  isNovuna,
}) => (
  <>
    {editing ? (
      <>
        <TextField
          value={value}
          prefix={'£'}
          aria-labelledby={id}
          onChange={(e) => onChange(e.target.value)}
        />
        <Text pt={2} sx={{ fontSize: 0 }}>
          {minDepositPercentage} minimum deposit
        </Text>
      </>
    ) : (
      <Flex sx={{ justifyContent: 'space-between' }}>
        <Text aria-labelledby={id}>{children}</Text>
        {isNovuna ? (
          <NovunaTextButton onClick={() => setEditing(true)}>
            Edit
          </NovunaTextButton>
        ) : (
          <TextButton
            onClick={() => setEditing(true)}
            pl={[1, 0, 0]}
            sx={{ fontSize: 1 }}
          >
            Edit
          </TextButton>
        )}
      </Flex>
    )}
    {errorMessage && (
      <Box mt={1}>
        <SectionError>{errorMessage}</SectionError>
      </Box>
    )}
  </>
);
interface UpdatedItemContentProps {
  labelledBy: string;
  isPbfTailoring?: boolean;
  isRepaidOver?: boolean;
  errorMessage?: string;
}

const UpdatedItemContent: React.FC<UpdatedItemContentProps> = ({
  labelledBy,
  isPbfTailoring = false,
  isRepaidOver,
  errorMessage,
  children,
}) => (
  <>
    <Flex>
      <Text
        ml={isPbfTailoring ? 0 : [2, 0, 0]}
        mt={isPbfTailoring ? 2 : 0}
        sx={{ order: isPbfTailoring ? 1 : [2, 1, 1] }}
        aria-labelledby={labelledBy}
      >
        {children} {isRepaidOver && <br />}
      </Text>

      <Badge
        variant='primary'
        ml={isPbfTailoring ? 2 : [0, 2, 2]}
        mt='auto'
        sx={{ order: isPbfTailoring ? 2 : [1, 2, 2], borderRadius: '6px' }}
      >
        Updated
      </Badge>
    </Flex>
    {errorMessage && (
      <Box mt={1}>
        <SectionError>{errorMessage}</SectionError>
      </Box>
    )}
  </>
);

interface ComparisonItemProps {
  label: string;
  idPrefix: string;
  disabled: boolean;
  updatedItem?: boolean;
  showPlaceholder?: boolean;
  widePlaceholder?: boolean;
  editDeposit?: EditDepositProps;
  depositError?: string;
  updatedItemSx?: string;
  isPbfTailoring?: boolean;
  isRepaidOver?: boolean;
}

export const ComparisonItem: React.FC<ComparisonItemProps> = ({
  label,
  updatedItem,
  idPrefix,
  showPlaceholder,
  widePlaceholder,
  editDeposit,
  depositError,
  children,
  updatedItemSx,
  isPbfTailoring,
  isRepaidOver,
  disabled,
}) => {
  const {
    theme: { channel },
  } = useThemeUI() as CompassDesignTheme;

  const isNovuna = channel === 'NOVUNA';

  const id = formatId(`${idPrefix}-${label}`);
  const isSmallScreen = window.innerWidth < 350;

  const wrapperSx: CompassDesignTheme = isNovuna
    ? {
        color: disabled ? 'monochrome.3' : undefined,
      }
    : {
        display: 'flex',
        flexDirection: isPbfTailoring ? 'column' : ['row', 'column', 'column'],
        flexWrap: 'wrap',
        justifyContent: 'space-between',
      };

  const Item = isPbfTailoring ? (
    <Box mt={isNovuna ? 3 : [3, 4, 4]} sx={{ ...wrapperSx }}>
      <Text sx={{ fontSize: 0, color: 'text' }} id={id}>
        {label}
      </Text>
      <Box mt={isNovuna ? 0 : [0, 2, 2]} sx={{ fontSize: [1, 2, 2] }}>
        {showPlaceholder ? (
          <Placeholder wide={widePlaceholder} />
        ) : editDeposit ? (
          <EditDeposit id={id} {...editDeposit} isNovuna={isNovuna}>
            {children}
          </EditDeposit>
        ) : updatedItem ? (
          <UpdatedItemContent
            labelledBy={id}
            isPbfTailoring={isPbfTailoring}
            isRepaidOver={isRepaidOver && isSmallScreen}
          >
            {children}
          </UpdatedItemContent>
        ) : (
          <Text
            mb={isSmallScreen ? 3 : 0}
            mt={2}
            sx={{
              textDecorationLine: updatedItemSx,
            }}
            aria-labelledby={id}
          >
            {children}
          </Text>
        )}
      </Box>
    </Box>
  ) : (
    <Box
      mt={isNovuna ? 3 : [3, 4, 4]}
      sx={{
        ...wrapperSx,
      }}
    >
      <Text sx={{ fontSize: 1, color: 'monochrome.3' }} id={id}>
        {label}
      </Text>
      <Box mt={isNovuna ? 0 : [0, 2, 2]} sx={{ fontSize: [1, 2, 2] }}>
        {showPlaceholder ? (
          <Placeholder wide={widePlaceholder} />
        ) : editDeposit ? (
          <EditDeposit id={id} {...editDeposit} isNovuna={isNovuna}>
            {children}
          </EditDeposit>
        ) : depositError ? (
          <UpdatedItemContent errorMessage={depositError} labelledBy={id}>
            {children}
          </UpdatedItemContent>
        ) : updatedItem ? (
          <UpdatedItemContent labelledBy={id}>{children}</UpdatedItemContent>
        ) : (
          <Text
            sx={{
              textDecorationLine: updatedItemSx,
            }}
            aria-labelledby={id}
          >
            {children}
          </Text>
        )}
      </Box>
    </Box>
  );
  return Item;
};

/** @jsx jsx */
import React from 'react';
import { Box, Flex, FlexProps, jsx, Text, useThemeUI } from 'theme-ui';
import { CompassDesignTheme } from '../../themes/abstractTheme';
import { AlertCircle } from '../Icons';
import { RedAlert } from '../Novuna';

export type SectionErrorProps = FlexProps;

export const SectionError: React.FC<SectionErrorProps> = ({
  children,
  ...props
}) => {
  const {
    theme: { channel },
  } = useThemeUI() as CompassDesignTheme;

  const isNovuna = channel === 'NOVUNA';

  return (
    <Flex {...props}>
      <Box sx={{ flex: '0 0 auto' }}>
        {isNovuna ? <RedAlert /> : <AlertCircle />}
      </Box>
      <Text
        ml='2'
        pr={['0', null, '3']}
        pt={isNovuna ? 0 : '1'}
        sx={{ fontSize: '1', color: 'tone.negative' }}
      >
        {children}
      </Text>
    </Flex>
  );
};

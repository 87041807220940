/** @jsx jsx */
import { Fragment } from 'react';
import { Box, Flex, jsx, Text } from 'theme-ui';
import { NovunaHeading } from '..';

interface PlaceholderProps {
  width: string;
}

const Placeholder: React.FC<PlaceholderProps> = ({ width }) => (
  <Box
    sx={{
      width,
      maxWidth: '100%',
      height: '24px',
      backgroundColor: 'tints.2',
      borderRadius: '12px',
    }}
  />
);

interface SummaryItemProps {
  id: string;
  label: string;
  value?: string;
  longerPlaceholder?: boolean;
  noMargin?: boolean;
}

const SummaryItem: React.FC<SummaryItemProps> = ({
  id,
  label,
  value,
  longerPlaceholder,
  noMargin,
}) => (
  <Flex mb={noMargin ? 0 : 2} sx={{ justifyContent: 'space-between' }}>
    <Text sx={{ color: 'monochrome.3' }}>{label}</Text>
    {value ? (
      <Text id={id} sx={{ color: 'monochrome.1' }}>
        {value}
      </Text>
    ) : (
      <Placeholder width={longerPlaceholder ? '100px' : '84px'} />
    )}
  </Flex>
);

export interface CM3QuoteSummaryProps {
  applicationId?: string;
  goodsDescription?: string;
  goodsAmount?: string;
  deposit?: string;
  loanAmount?: string;
  repaymentTerm?: string;
  deferralPeriod?: string;
  apr?: string;
  representativeAPR?: string;
  interestPayable?: string;
  totalAmountPayable?: string;
  monthlyRepayment?: string;
  backToStoreTextButton?: React.ReactNode;
}

export const CM3QuoteSummary: React.FC<CM3QuoteSummaryProps> = ({
  applicationId,
  goodsDescription,
  goodsAmount,
  deposit,
  loanAmount,
  repaymentTerm,
  deferralPeriod,
  apr,
  representativeAPR,
  interestPayable,
  totalAmountPayable,
  monthlyRepayment,
  backToStoreTextButton,
}) => (
  <Fragment>
    <Box mb={4}>
      {applicationId ? (
        <Text
          id='quote-summary-applicationid'
          data-test-id='quote-summary-applicationid'
          sx={{
            color: 'monochrome.1',
            fontWeight: 'bold',
          }}
        >
          {applicationId}
        </Text>
      ) : (
        <Placeholder width='129px' />
      )}
    </Box>
    <NovunaHeading as='h3' mb={3}>
      Purchase
    </NovunaHeading>
    <Box mb={4}>
      {goodsDescription ? (
        <Text
          id='quote-summary-goodsdescription'
          sx={{ color: 'monochrome.1' }}
        >
          {goodsDescription}
        </Text>
      ) : (
        <Placeholder width='268px' />
      )}
    </Box>
    <NovunaHeading as='h3' mb={3}>
      Loan application
    </NovunaHeading>
    <SummaryItem
      id='quote-summary-totalcost'
      label='Total cost'
      value={goodsAmount}
    />
    <SummaryItem
      id='quote-summary-deposit'
      label='Deposit'
      value={deposit}
      longerPlaceholder
    />
    <SummaryItem
      id='quote-summary-loanamount'
      label='Amount borrowed'
      value={loanAmount}
    />
    <SummaryItem
      id='quote-summary-repaymentterm'
      label='Repaid over'
      value={repaymentTerm}
      longerPlaceholder
    />
    {deferralPeriod && (
      <SummaryItem
        id='quote-summary-deferralperiod'
        label='Deferred for'
        value={deferralPeriod}
      />
    )}
    <SummaryItem id='quote-summary-apr' label='Interest rate' value={apr} />
    <SummaryItem
      id='quote-summary-representativeapr'
      label='Representative APR'
      value={representativeAPR}
      longerPlaceholder
    />
    <SummaryItem
      id='quote-summary-interestpayable'
      label='Interest payable'
      value={interestPayable}
    />
    <SummaryItem
      id='quote-summary-totalpayable'
      label='Total payable'
      value={totalAmountPayable}
      longerPlaceholder
    />
    <SummaryItem
      id='quote-summary-monthlyrepayment'
      label='Monthly repayment'
      value={monthlyRepayment}
      noMargin
    />
    {backToStoreTextButton && backToStoreTextButton}
  </Fragment>
);

export interface PFQuoteSummaryProps {
  orderReference?: string;
  amountBorrowed?: string;
  repaidOver?: string;
  interestRate?: string;
  representativeAPR?: string;
  interestPayable?: string;
  totalAmountPayable?: string;
  monthlyRepayment?: string;
}

export const PFQuoteSummary: React.FC<PFQuoteSummaryProps> = ({
  orderReference,
  amountBorrowed,
  repaidOver,
  interestRate,
  representativeAPR,
  interestPayable,
  totalAmountPayable,
  monthlyRepayment,
}) => (
  <Fragment>
    <Box mb={4}>
      {orderReference ? (
        <Text
          sx={{
            color: 'monochrome.1',
            fontWeight: 'bold',
          }}
        >
          {orderReference}
        </Text>
      ) : (
        <Placeholder width='129px' />
      )}
    </Box>

    <NovunaHeading as='h3' mb={3}>
      Your loan application
    </NovunaHeading>

    <SummaryItem
      id='quote-summary-amountborrowed'
      label='Amount borrowed'
      value={amountBorrowed}
    />
    <SummaryItem
      id='quote-summary-repaidover'
      label='Repaid over'
      value={repaidOver}
      longerPlaceholder
    />
    <SummaryItem
      id='quote-summary-interestrate'
      label='Interest rate'
      value={interestRate}
    />
    <SummaryItem
      id='quote-summary-representativeapr'
      label='Representative APR'
      value={representativeAPR}
      longerPlaceholder
    />
    <SummaryItem
      id='quote-summary-interestpayable'
      label='Interest payable'
      value={interestPayable}
    />
    <SummaryItem
      id='quote-summary-totalpayable'
      label='Total payable'
      value={totalAmountPayable}
      longerPlaceholder
    />
    <SummaryItem
      id='quote-summary-monthlyrepayment'
      label='Monthly repayment'
      value={monthlyRepayment}
      noMargin
    />
  </Fragment>
);

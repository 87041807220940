/** @jsx jsx */
import React from 'react';
import { Box, ButtonProps, jsx, Text } from 'theme-ui';
import { NovunaArrowLeft, NovunaArrowRight } from '../Novuna';

export enum TertiaryLinkDirection {
  FORWARDS = 'FORWARDS',
  BACKWARDS = 'BACKWARDS',
}

interface TertiaryLinkProps extends ButtonProps {
  direction: TertiaryLinkDirection;
  text: string;
  isWhite?: boolean;
  dataTestId?: string;
  [key: string]: unknown;
}

export const TertiaryLink = React.forwardRef<
  HTMLButtonElement,
  TertiaryLinkProps
>(({ direction, text, isWhite = false, dataTestId = '', ...props }, ref) => (
  <button
    data-test-id={dataTestId}
    {...props}
    sx={{
      height: '40px',
      color: isWhite ? '#fff' : 'secondaryPurple',
      fontSize: 1,
      fontFamily: 'heading',
      fontWeight: 'heading',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: 'transparent',
      border: 0,
      padding: 0,
      cursor: 'pointer',
      '&:hover, &:focus': {
        color: isWhite ? 'tints.3' : '#86609c',
      },
      '&:visited': {
        color: 'monochrome.1',
      },
    }}
    ref={ref}
  >
    {direction === TertiaryLinkDirection.BACKWARDS && (
      <Box
        sx={{
          marginRight: '19px',
          display: 'flex',
          alignItems: 'center',
          '&:hover, &:focus, &:focus-within': {
            color: '#86609c',
          },
        }}
      >
        <NovunaArrowLeft />
      </Box>
    )}
    <Text>{text}</Text>
    {direction === TertiaryLinkDirection.FORWARDS && (
      <Box
        sx={{
          marginLeft: '19px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <NovunaArrowRight />
      </Box>
    )}
  </button>
));

TertiaryLink.displayName = 'TertiaryLink';

/* eslint-disable react/prop-types */
/** @jsx jsx */
import React from 'react';
import { Box, jsx, Label, Text } from 'theme-ui';
import { ErrorMessage } from '..';
import { Tone } from '../tones';

interface InputWrapperProps {
  id: string;
  label?: string;
  tone?: Tone;
  error?: string;
  description?: string;
  descriptionTop?: boolean;
  stacked?: boolean;
  fieldset?: boolean;
}

export const FieldSetContext = React.createContext<string | undefined>(
  undefined
);

export const InputWrapper: React.FunctionComponent<InputWrapperProps> = (
  props
) => {
  const {
    children,
    id,
    label,
    tone = 'neutral',
    error,
    description,
    fieldset = false,
  } = props;

  let labelComponent;
  const labelSx = { color: 'monochrome.1', fontWeight: 'heading' };

  if (fieldset) {
    labelComponent = (
      <Text id={id} sx={{ ...labelSx }}>
        {label}
      </Text>
    );
  } else {
    labelComponent = (
      <Label
        htmlFor={id}
        id={`${id}_label`}
        sx={{
          ...labelSx,
          width: 'fit-content',
        }}
      >
        {label}
      </Label>
    );
  }

  return (
    <FieldSetContext.Provider value={fieldset ? id : undefined}>
      {label && labelComponent}
      {description && (
        <Text sx={{ display: 'inline-block', color: 'monochrome.3' }}>
          {description}
        </Text>
      )}
      {tone == 'negative' && <ErrorMessage mt={1} error={error} />}
      <Box mt={2}>{children}</Box>
    </FieldSetContext.Provider>
  );
};

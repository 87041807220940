/** @jsx jsx */
import { Box, Flex, jsx, Text } from 'theme-ui';
import { RedAlert } from '../../Novuna';

interface NovunaInputHeaderProps {
  title: string;
  hint?: string;
  error?: string;
}

export const NovunaInputHeader = ({
  title,
  hint,
  error,
}: NovunaInputHeaderProps) => (
  <Box sx={{ mb: 2 }}>
    <Text
      sx={{
        color: 'monochrome.1',
        fontWeight: 'heading',
      }}
    >
      {title}
    </Text>
    <Text sx={{ color: 'monochrome.3' }}>{hint}</Text>
    {error && (
      <Flex sx={{ alignItems: 'center' }}>
        <RedAlert />
        <Text sx={{ color: 'invalidRed', ml: 2 }}>{error}</Text>
      </Flex>
    )}
  </Box>
);

/**  @jsx jsx */
import Skeleton from 'react-loading-skeleton';
import { Box, jsx } from 'theme-ui';
export { Skeleton };

export const ButtonSkeleton = () => <Skeleton height='3em' width='12em' />;

export const DocumentSkeleton = () => (
  <Box sx={{ maxWidth: '300px' }}>
    <Skeleton
      wrapper='div'
      //   ISO 216 A series is defined by a ratio of √2
      sx={{ paddingTop: `${Math.sqrt(2) * 100}%` }}
    />
  </Box>
);

/* eslint-disable react/prop-types */
/** @jsx jsx */
import React, { Children } from 'react';
import { Grid, GridProps, jsx, SxStyleProp, Text, useThemeUI } from 'theme-ui';
import { CompassDesignTheme } from '../../themes';
import { TableHeadingRow } from './TableHeadingRow';
import { TableRow } from './TableRow';

export interface TableProps extends GridProps {
  columnNames?: string[];
  emptyTableMessage?: string;
  width?: (string | null)[];
}

export const Table = React.forwardRef<HTMLDivElement, TableProps>(
  (props, ref) => {
    const {
      theme: { channel },
    } = useThemeUI() as CompassDesignTheme;
    const isNovuna = channel === 'NOVUNA';

    const {
      columnNames,
      children,
      width,
      emptyTableMessage = 'No entries to display',
      ...rest
    } = props;

    let sx: SxStyleProp = { ...props.sx, width };
    if (isNovuna) {
      sx = {
        ...sx,
        gap: 0,
        border: '1px solid',
        borderColor: 'tints.2',
        borderRadius: 'panel',
        backgroundColor: 'white',
      };
    }

    const emptyTableRow = (
      <TableRow>
        <Text>{emptyTableMessage}</Text>
      </TableRow>
    );

    return (
      <Grid {...rest} sx={sx} columns={1} ref={ref}>
        {columnNames?.length && (
          <TableHeadingRow>
            {columnNames.map((c) => (
              <Text key={c}>{c}</Text>
            ))}
          </TableHeadingRow>
        )}
        {children && Children.count(children) ? children : emptyTableRow}
      </Grid>
    );
  }
);

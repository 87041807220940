/** @jsx jsx */
import { jsx } from 'theme-ui';
import { ReactComponent as NovunaCFStackedWhite } from './novuna-cf-stacked-white.svg';
import { ReactComponent as NovunaCM3StackedGreenWhite } from './novuna-cm3-stacked-green-white.svg';
import { ReactComponent as NovunaCM3Stacked } from './novuna-cm3-stacked-correct.svg';
import { ReactComponent as NovunaCM3LoginDesktop } from './novuna-cf-credit-master-3-desktop-colour.svg';
import { ReactComponent as NovunaCM3PowersHighStreet } from './novuna-flexibility-powers-high-street.svg';
import { ReactComponent as NovunaCM3TomorrowTogether } from './novuna-strapline-tomorrow-together.svg';
import { ReactComponent as NovunaPBFHorizontalGreenWhite } from './novuna-pbf-horizontal-green-white.svg';
import { ReactComponent as NovunaPBFStackedGreenWhite } from './novuna-pbf-stacked-white-with-green-n.svg';
import { ReactComponent as NovunaPFHorizontalGreenWhite } from './novuna-pf-horizontal-white-with-green-n.svg';
import { ReactComponent as NovunaPFStackedGreenWhite } from './novuna-pf-stacked-green-white.svg';
import { ReactComponent as NovunaPFStackedWhite } from './novuna-pf-stacked-white.svg';

export const NovunaCM3StackedGreenWhiteLogo = () => (
  <NovunaCM3StackedGreenWhite />
);
export const NovunaCM3StackedLogo = () => <NovunaCM3Stacked />;
export const NovunaCM3LoginDesktopLogo = () => <NovunaCM3LoginDesktop />;
export const NovunaCM3PowersHighStreetLogo = () => (
  <NovunaCM3PowersHighStreet />
);
export const NovunaCM3TomorrowTogetherLogo = () => (
  <NovunaCM3TomorrowTogether />
);
export const NovunaCFStackedWhiteLogo = () => <NovunaCFStackedWhite />;
export const NovunaPBFHorizontalGreenWhiteLogo = () => (
  <NovunaPBFHorizontalGreenWhite />
);
export const NovunaPBFStackedGreenWhiteLogo = () => (
  <NovunaPBFStackedGreenWhite />
);
export const NovunaPFHorizontalGreenWhiteLogo = () => (
  <NovunaPFHorizontalGreenWhite />
);
export const NovunaPFStackedGreenWhiteLogo = () => (
  <NovunaPFStackedGreenWhite />
);
export const NovunaPFStackedWhiteLogo = () => <NovunaPFStackedWhite />;

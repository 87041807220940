/** @jsx jsx */
import { jsx, Box, Close, useThemeUI } from 'theme-ui';
import { NovunaHeading } from '..';
import { Heading } from '../Heading';

interface ReactModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  size: 'small' | 'small-medium' | 'medium' | 'large';
  title?: string;
  noScroll?: boolean;
  backgroundClick?: boolean;
}

export const ReactModal: React.FC<ReactModalProps> = ({
  isOpen,
  setIsOpen,
  size,
  title,
  noScroll,
  backgroundClick = true,
  children,
}) => {
  const {
    theme: { channel },
  } = useThemeUI() as any;

  const isNovuna = channel === 'NOVUNA';

  if (!isOpen) {
    return null;
  }

  let maxWidth;
  let maxDialogHeight;

  switch (size) {
    case 'small':
      maxWidth = '400px';
      maxDialogHeight = 'calc(100vh - 89px)';
      break;
    case 'small-medium':
      maxWidth = '600px';
      maxDialogHeight = 'calc(100vh - 101px)';
      break;
    case 'medium':
      maxWidth = '720px';
      maxDialogHeight = 'calc(100vh - 101px)';
      break;
    case 'large':
      maxWidth = '100%';
      maxDialogHeight = 'calc(100vh - 101px)';
      break;
  }

  const Title = isNovuna ? (
    <NovunaHeading as='h2'>{title}</NovunaHeading>
  ) : (
    <Heading underline='full' as='h2' visualSize={size === 'small' ? 3 : 4}>
      {title}
    </Heading>
  );

  return (
    <Box
      id='modal-wrapper'
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(26,26,26,0.64)',
        zIndex: 10,
      }}
      onClick={(e: any) => {
        if (e.target.id === 'modal-wrapper' && backgroundClick) {
          setIsOpen(false);
        }
      }}
    >
      <Box
        id='modal'
        sx={{
          position: 'relative',
          display: 'block !important',
          top: '50%',
          transform: 'translateY(-50%)',
          width: 'calc(100% - 32px)',
          maxWidth,
          maxHeight: '100vh',
          backgroundColor: 'white',
          borderRadius: '16px',
          margin: 'auto',
          paddingLeft: 4,
          paddingRight: 4,
          marginBottom: 3,
          ...(isNovuna ? { borderRadius: 'panel', padding: 6 } : {}),
        }}
      >
        <Box
          sx={{
            display: 'flex',
            paddingTop: isNovuna ? 0 : 4,
            paddingBottom: title ? (isNovuna ? 2 : 4) : 0,
          }}
        >
          {title && Title}
          <Close
            aria-label='close modal'
            sx={{
              cursor: 'pointer',
              variant: 'modal.close',
              display: 'flex',
              alignItems: 'flex-end',
              marginLeft: 'auto',
            }}
            type='button'
            onClick={() => setIsOpen(false)}
          />
        </Box>
        <Box
          id='modal-content'
          sx={{
            paddingBottom: 4,
            overflowY: noScroll ? 'inherit' : 'auto',
            maxHeight: maxDialogHeight,
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

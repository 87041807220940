/** @jsx jsx */
import { useRef, useState } from 'react';
import { jsx, Text, Box, Flex } from 'theme-ui';
import { TertiaryLink, TertiaryLinkDirection } from '../../..';
import { NovunaArrowDown } from '../../Icons';
import { CM3NavBaseProps } from './NavProps';
import useOutsideCloser from './useOutsideCloser';

const getUserInitials = (username: string): string => {
  const names = username.split(' ');
  const firstNameInitial = names[0][0].toUpperCase();
  let lastNameInitial = '';
  if (names.length > 1) {
    lastNameInitial = names[1][0].toUpperCase();
  }
  return `${firstNameInitial}${lastNameInitial}`;
};

export type UserProfileProps = {
  username: string;
} & Pick<CM3NavBaseProps, 'logoutUser'> & Pick<CM3NavBaseProps, 'managePw'>;

const UserProfile = ({ username, managePw, logoutUser }: UserProfileProps) => {
  const [openMenu, setOpenMenu] = useState(false);

  const wrapperRef = useRef(null);
  useOutsideCloser({ ref: wrapperRef, closeMenu: () => setOpenMenu(false) });

  const size = ['40px', null, null, '48px'];

  return (
    <Flex sx={{ position: 'relative' }} onClick={() => setOpenMenu(true)}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: size,
          height: size,
          backgroundColor: 'tints.1',
          borderRadius: '50%',
          marginTop: 'auto',
          marginBottom: 'auto',
          cursor: ['default', null, null, 'pointer'],
        }}
      >
        <Text sx={{ color: 'monochrome.7', fontWeight: 'heading' }}>
          {getUserInitials(username)}
        </Text>
      </Box>
      <Box
        sx={{
          display: ['none', null, null, 'flex'],
          alignItems: 'center',
          cursor: ['default', null, null, 'pointer'],
        }}
      >
        <NovunaArrowDown />
      </Box>

      <Box
        ref={wrapperRef}
        sx={{
          display: ['none', null, null, openMenu ? 'block' : 'none'],
          position: 'absolute',
          width: '250px',
          right: 0,
          top: '72px',
          backgroundColor: 'white',
          border: '1px solid',
          borderColor: 'tints.2',
          boxShadow: '0 3px 10px 2px grey',
          padding: 4,
          zIndex: '2',
        }}
      >
        {managePw && (
          <TertiaryLink
            id='cm3-manage-password'
            text='Manage password'
            direction={TertiaryLinkDirection.FORWARDS}
            onClick={() => {
              setOpenMenu(false);
              managePw();
            }}
          />
        )}
        <TertiaryLink
          id='cm3-nav-logout'
          text='Sign out'
          direction={TertiaryLinkDirection.FORWARDS}
          onClick={() => {
            setOpenMenu(false);
            logoutUser();
          }}
        />
        <Text sx={{ color: 'monochrome.3', marginTop: 3 }}>{username}</Text>
      </Box>
    </Flex>
  );
};

export default UserProfile;

/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/** @jsx jsx */
import React, { useContext } from 'react';
import { Flex, Input, InputProps, jsx, Text } from 'theme-ui';
import { FieldSetContext } from '../InputWrapper/InputWrapper';
import { mapToneBackgroundColor, mapToneColor, Tone } from '../tones';

interface TextFieldProps extends InputProps {
  tone?: Tone;
  description?: string;
  prefix?: string;
  flexSx?: object;
  short?: boolean;
  [key: string]: unknown;
}

export const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      tone = 'neutral',
      description,
      prefix,
      flexSx,
      id,
      name,
      value,
      short,
      ...rest
    },
    ref
  ) => {
    const prefixSx: any = {
      position: 'relative',
      '&::before': (theme: any) => ({
        ...theme.forms.prefix,
        content: `'${prefix}'`,
        color: value
          ? theme.colors.text
          : theme.forms.input['::placeholder'].color,
      }),
    };

    const context = useContext(FieldSetContext);

    return (
      <Flex
        sx={{
          flexDirection: 'column',
          ...(prefix ? prefixSx : {}),
          ...flexSx,
        }}
      >
        {description && (
          <Text mb={2} sx={{ color: 'monochrome.3' }}>
            {description}
          </Text>
        )}
        <Input
          {...rest}
          value={value}
          ref={ref}
          id={id ?? name}
          name={name}
          {...(context ? { 'aria-describedby': context } : {})}
          sx={{
            maxWidth: ['100%', null, short ? '306px' : '640px'],
            maxHeight: '3rem',
            borderColor: mapToneColor(tone),
            backgroundColor: mapToneBackgroundColor(tone),
            ...(prefix ? { paddingLeft: '28px' } : {}),
          }}
          autoComplete='off'
        />
      </Flex>
    );
  }
);

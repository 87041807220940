/* eslint-disable react/prop-types */
/** @jsx jsx */
import React, { useMemo } from 'react';
import { Box, Input, InputProps, jsx, Label, Text } from 'theme-ui';
import { ErrorMessage } from '..';
import { NovunaCheckboxTick } from '../../Novuna/Icons';

interface InlineCheckboxProps extends InputProps {
  checked?: boolean;
  error?: string;
  tone?: string;
  dataTestId?: string;
  [key: string]: unknown;
}

export const InlineCheckbox = React.forwardRef<
  HTMLInputElement,
  InlineCheckboxProps
>(
  (
    {
      children,
      id: _id,
      name,
      checked = false,
      tone = 'neutral',
      error,
      dataTestId = '',
      ...rest
    },
    ref
  ) => {
    const id = _id ?? name;

    const showError = useMemo(() => {
      return tone === 'negative' && error;
    }, [tone, error]);

    return (
      <Box>
        {showError && <ErrorMessage mb={2} error={error} />}
        <Label
          htmlFor={id}
          sx={{ width: 'fit-content', fontSize: 1 }}
          data-test-id={dataTestId}
        >
          <Input
            {...rest}
            ref={ref}
            id={id}
            variant='checkbox'
            type='checkbox'
            checked={checked}
            sx={{
              '&:focus + div': {
                outline: '2px solid',
                outlineColor: 'monochrome.1',
              },
            }}
          />
          <Box variant='checkbox.box'>{checked && <NovunaCheckboxTick />}</Box>
          <Text sx={{ width: 'fit-content' }} ml='12px' paddingTop={0}>
            {children}
          </Text>
        </Label>
      </Box>
    );
  }
);

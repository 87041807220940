/* eslint-disable react/prop-types */
/** @jsx jsx */
import {
  Box,
  Flex,
  jsx,
  Styled,
  SxStyleProp,
  Text,
  useThemeUI,
} from 'theme-ui';
import { CompassDesignTheme } from '../../themes/abstractTheme';
import { Heading, HeadingProps } from '../Heading';
import { NovunaArrowDown, NovunaArrowUp } from '../Novuna';
import { formatId } from '../utils';
import { ReactComponent as Minus } from './minus-outline.svg';
import { ReactComponent as Plus } from './plus-outline.svg';

export interface AccordionEntryProps {
  sectionNumber: number;
  title: string;
  description: string;
  headingAs: HeadingProps['as'];
  headingVariant?: string;
  isOpen: boolean;
  setOpen: (o: boolean) => void;
  breakAfter?: boolean;
  testIdPrefix?: string;
}

export const AccordionEntry: React.FunctionComponent<AccordionEntryProps> = ({
  children,
  description,
  sectionNumber,
  title,
  headingAs,
  headingVariant = 'secondary',
  isOpen,
  setOpen,
  breakAfter = false,
  testIdPrefix,
}) => {
  const {
    theme: { channel },
  } = useThemeUI() as CompassDesignTheme;

  const isNovuna = channel === 'NOVUNA';

  let contentSx: SxStyleProp = {
    pageBreakAfter: breakAfter ? 'always' : 'initial',
  };

  if (!isOpen) {
    contentSx = {
      position: 'absolute',
      left: '-100rem',
      width: '1rem',
      height: '1rem',
      overflow: 'hidden',
      '@media print': {
        position: 'initial',
        left: 'initial',
        width: 'initial',
        height: 'initial',
        overflow: 'initial',
      },
      ...contentSx,
    };
  }

  if (isNovuna) {
    return (
      <Box mb={3}>
        <button
          sx={{
            width: '100%',
            backgroundColor: '#e0dde2',
            border: '0',
            borderRadius: '5px',
            px: 3,
            py: '12px',
            mb: description ? 3 : 2,
            cursor: 'pointer',
          }}
          onClick={() => {
            setOpen(!isOpen);
          }}
          data-test-id={formatId(title, { prefix: testIdPrefix })}
        >
          <Flex
            sx={{
              color: 'secondaryPurple',
              fontSize: 1,
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Styled.h4 as='label'>{title}</Styled.h4>
            {isOpen ? <NovunaArrowUp /> : <NovunaArrowDown />}
          </Flex>
        </button>
        {description && (
          <Text mb={3} sx={{ color: 'monochrome.3' }}>
            {description}
          </Text>
        )}
        <Box sx={contentSx}>{children}</Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: '100%',
        borderTop: '2px solid',
        borderColor: 'monochrome.4',
      }}
      pt={3}
    >
      <button
        aria-expanded={isOpen}
        sx={{
          all: 'inherit',
          cursor: 'pointer',
          border: 'none',
          background: 'transparent',
          width: '100%',
          padding: 0,
          margin: 0,
        }}
        onClick={() => {
          setOpen(!isOpen);
        }}
      >
        <Box
          pl={4}
          pr={6}
          sx={{
            paddingLeft: 4,
            paddingRight: (t: CompassDesignTheme) => `${t.space[3] + 40}px`,
            position: 'relative',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              color: 'secondary',
              top: 0,
              right: 0,
              '@media print': {
                display: 'none',
              },
            }}
          >
            {isOpen ? <Minus /> : <Plus />}
          </Box>

          <Heading
            as='div'
            visualSize={3}
            sx={{
              fontWeight: 'bold',
              position: 'absolute',
              top: 0,
              left: 0,
            }}
            underline='none'
          >
            {sectionNumber}
          </Heading>

          <Heading
            as={headingAs}
            visualSize={3}
            variant={headingVariant}
            sx={{
              fontWeight: 'bold',
              color: 'secondary',
            }}
          >
            {title}
          </Heading>

          <Text mt={2} mb={4}>
            {description}
          </Text>
        </Box>
      </button>

      <Box sx={contentSx}>{children}</Box>
    </Box>
  );
};

export { useBreakpointIndex, useResponsiveValue } from '@theme-ui/match-media';
export {
  Badge,
  Box,
  Container,
  Flex,
  Grid,
  Input,
  jsx,
  Label,
  Link,
  Radio,
  Select,
  Spinner,
  Text,
  Styled,
  ThemeProvider,
  useThemeUI,
} from 'theme-ui';

import { Theme as _Theme } from 'theme-ui';
export type Theme = _Theme;

export * from './Accordion';
export * from './Alert';
export * from './AuthScreen';
export * from './Button';
export * from './Card';
export * from './Footer';
export * from './GlobalStyle';
export * from './Heading';
export * from './Icons';
export * from './InfoBox';
export * from './Input';
export * from './Layout';
export * from './List';
export * from './LoanComparisonCard';
export * from './LoanSummary';
export * from './LoanSummary/SummaryItem';
export * from './Logos';
export * from './Modal';
export * from './Navbar';
export * from './Novuna';
export * from './Placeholder';
export * from './PrivacyPolicy';
export * from './Ribbon';
export * from './SectionError';
export * from './Skeleton';
export * from './SoftSearchCard';
export * from './ScreenReaderLabel';
export * from './Table';
export * from './Table/TableRow';
export * from './TableItem';
export * from './TailoringOptions';
export * from './TextButton';
export * from './ReactModal';
export * from './LoadingModal';
export * from './Pagination';
export * from './PasswordStrengthBar';
export * from './AccessibilityBanner';

export const pxToRem = (sizes: number[]) =>
  sizes.map((size) => `${size / 16}rem`);

/* eslint-disable react/prop-types */
/** @jsx jsx */
import React, { useCallback, useContext } from 'react';
import {
  jsx,
  Label,
  RadioProps,
  SxStyleProp,
  Text,
  useThemeUI,
} from 'theme-ui';
import { FieldSetContext } from '../InputWrapper/InputWrapper';
interface RadioOptionProps extends RadioProps {
  labelSx?: SxStyleProp;
  standalone?: boolean;
}

export const RadioOption = React.forwardRef<HTMLInputElement, RadioOptionProps>(
  ({ children, onClick, labelSx, standalone = false, ...rest }, ref) => {
    const context = useContext(FieldSetContext);

    /* Safari workaround
       Safari doesn't focus a radio button with the mouse, which means blur will never fire.
       This has a knock on effect for techniques that rely on focus/blur, like validation.

       Merging handler functions is easier than merging a local ref with the forwarded one.
     */
    const localClick = useCallback(
      (e) => {
        if (onClick) {
          onClick(e);
        }
        e.target.focus();
      },
      [onClick]
    );

    const {
      theme: {
        forms: { radio: radioSx },
      },
    } = useThemeUI() as any;

    const radio = (
      <input
        type='radio'
        {...rest}
        ref={ref}
        onClick={localClick}
        sx={{
          ...radioSx,
          backgroundColor: 'inherit',
        }}
        {...(context ? { 'aria-describedby': context } : {})}
      />
    );

    if (standalone) {
      return radio;
    }

    return (
      <Label
        sx={{
          width: 'fit-content',
          alignItems: 'center',
          fontSize: 2,
          marginBottom: 3,
          '&:last-child': { marginBottom: 0 },
        }}
      >
        {radio}
        <Text ml='12px'>{children}</Text>
      </Label>
    );
  }
);

/* eslint-disable react/prop-types */
/** @jsx jsx */
import React from 'react';
import { Box, BoxProps, jsx } from 'theme-ui';

interface TableItemProps extends BoxProps {
  border?: boolean;
}

export const TableItem = React.forwardRef<HTMLDivElement, TableItemProps>(
  (props, ref) => {
    const { children, sx, border, ...rest } = props;
    return (
      <Box
        {...rest}
        ref={ref}
        sx={{
          borderTop: border === false ? undefined : '2px solid',
          borderColor: 'monochrome.5',
          ...sx,
        }}
        pt={border === false ? 0 : 3}
        pb={4}
        pl={4}
      >
        {children}
      </Box>
    );
  }
);

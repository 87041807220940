/* eslint-disable react/prop-types */
/** @jsx jsx */
import { Text, Box } from 'theme-ui';
import { jsx, Input } from 'theme-ui';
import { Heading } from '../Heading';
import { Button } from '../Button';
import { Continue } from '../Icons';
import { InputWrapper } from '../Input';
import { Skeleton, ButtonSkeleton } from '../Skeleton';
import { Fragment } from 'react';

// This is exported as a component to allow it to be used as a skeleton scree
// before redirecting to the auth service.

interface AuthScreenProps {
  loading: boolean;
}

export const AuthScreen: React.FunctionComponent<AuthScreenProps> = ({
  loading,
}) => (
  <div>
    <Box my={5}>
      <Heading as={'h1'} visualSize={5} underline='partial' skeleton={loading}>
        Retail loan application
      </Heading>
    </Box>

    <Text sx={{ fontSize: 2 }} my={3}>
      {(!loading && (
        <Fragment>
          To make sure it&apos;s you, and to keep your details safe, we are
          texting an access code to your mobile.
        </Fragment>
      )) || <Skeleton count={2} />}
    </Text>

    {(!loading && (
      <InputWrapper
        label='Access code'
        description='Six digit code (eg 123456)'
        id='access-code'
        stacked
      >
        <Input sx={{ maxWidth: '14em' }} id='access-code' />
      </InputWrapper>
    )) || <Skeleton height='3em' />}

    <Box my={3}>
      {(!loading && (
        <Button>
          Continue
          <Continue />
        </Button>
      )) || <ButtonSkeleton />}
    </Box>

    <Box my={3}>
      <Heading as={'h3'} visualSize={3} underline='full' skeleton={loading}>
        <Fragment>If you haven&apos;t received a code</Fragment>
      </Heading>
    </Box>

    <Text sx={{ fontSize: 1 }}>
      {(!loading && (
        <Fragment>
          The access code is sent by text message to the phone number you
          provided with your loan application.
        </Fragment>
      )) || <Skeleton count={2} />}
    </Text>

    <Box my={3}>
      {(!loading && (
        <Button variant='secondary'>
          Re-send
          <Continue />
        </Button>
      )) || <ButtonSkeleton />}
    </Box>
  </div>
);

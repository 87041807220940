/* eslint-disable react/prop-types */
/** @jsx jsx */
import React from 'react';
import { jsx, Select, SelectProps, useThemeUI, SxStyleProp } from 'theme-ui';
import { Tone, mapToneColor } from '../tones';

interface SelectFieldProps extends SelectProps {
  tone?: Tone;
  touched?: boolean;
  labelSx?: SxStyleProp;
  short?: boolean;
  [key: string]: unknown;
}

export const SelectField = React.forwardRef<
  HTMLSelectElement,
  SelectFieldProps
>(
  (
    { tone = 'neutral', children, value, labelSx, short = false, ...rest },
    ref
  ) => {
    const {
      theme: {
        forms: {
          select: {
            'option[value=""]:first-child': { color: placeholderColor },
            'option:not(:first-child)': { color: optionColor },
          },
        },
        channel,
      },
    } = useThemeUI() as any;

    const isNovuna = channel === 'NOVUNA';

    const novunaSx: any = {};

    if (isNovuna) {
      novunaSx.maxWidth = ['100%', null, short ? '306px' : '620px'];
      novunaSx.height = '48px';
    }

    return (
      <Select
        {...rest}
        ref={ref}
        value={value}
        sx={{
          ...novunaSx,
          borderColor: mapToneColor(tone),
          color: value ? optionColor : placeholderColor,
          ...labelSx,
        }}
      >
        {children}
      </Select>
    );
  }
);

/* eslint-disable react/prop-types */
/** @jsx jsx */
import React from 'react';
import { Flex, Text, jsx, useThemeUI } from 'theme-ui';
import { useResponsiveValue } from '@theme-ui/match-media';
import { Box } from 'theme-ui';
import { Tone } from '../tones';
import { TickCircle } from '../../Icons';
import { NovunaInputHeader } from '..';

interface ReadOnlyWrapperProps {
  label: string;
  description?: string;
  action?: React.ReactNode;
  tone?: Tone;
  descriptionTop?: boolean;
  stacked?: boolean;
  borderless?: boolean;
}

export const ReadOnlyWrapper: React.FunctionComponent<ReadOnlyWrapperProps> = (
  props
) => {
  const {
    theme: { channel },
  } = useThemeUI() as any;

  const isNovuna = channel === 'NOVUNA';

  const {
    children,
    label,
    action,
    tone = 'neutral',
    description,
    descriptionTop,
    stacked = false,
    borderless = false,
  } = props;

  const showStacked = useResponsiveValue([true, true, stacked ? true : false]);

  if (isNovuna) {
    return (
      <Box>
        <Flex sx={{ justifyContent: 'space-between', alignItems: 'flex-end' }}>
          <NovunaInputHeader title={label} hint={description} />
          {action}
        </Flex>

        <Box
          sx={{
            padding: '12px',
            borderRadius: 'panel',
            border: '2px solid',
            borderColor: 'monochrome.5',
            backgroundColor: 'form.input.disabled.background',
          }}
        >
          {children}
        </Box>
      </Box>
    );
  }

  let borderStyle: { [key: string]: string } = {
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: 'monochrome.4',
  };

  if (borderless) {
    borderStyle = {};
  }

  const descriptionComponent = description && (
    <Text mt={2} sx={{ fontSize: 1 }}>
      {description}
    </Text>
  );

  return (
    <Box
      sx={{
        display: ['block', null, showStacked ? 'block' : 'flex'],
        flexDirection: 'row',
      }}
    >
      {/* Label container */}
      <Box
        mb={2}
        pr={['0', null, '2']}
        sx={{
          flexShrink: 0,
          flexGrow: 312,
          flexBasis: ['auto', null, showStacked ? 'auto' : 0],
        }}
      >
        <Flex>
          <Text sx={{ fontSize: '3', fontWeight: 'lighter' }}>{label}</Text>
          <Box
            ml={2}
            pt={1}
            sx={{
              flexShrink: 0,
              minWidth: '24px', // reserve space for the green tick so label text doesn't surprise wrap
            }}
          >
            {tone === 'positive' ? <TickCircle /> : null}
          </Box>
        </Flex>
        {/* Description */}
        {(descriptionTop || !showStacked) && descriptionComponent}
      </Box>
      {/* Contents go here */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          flexGrow: 392,
          flexBasis: 0,
        }}
      >
        <Box
          sx={{
            ...borderStyle,
            flexGrow: 1,
            fontSize: 2,
            paddingLeft: 3,
            paddingBottom: '12px',
          }}
        >
          {children}
        </Box>
        {action && (
          <Box pl={4} sx={{ fontSize: 1 }}>
            {action}
          </Box>
        )}
      </Box>
      {!descriptionTop && showStacked && descriptionComponent}
    </Box>
  );
};

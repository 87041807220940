/** @jsx jsx */
import React from 'react';
import {
  Box,
  Container,
  Divider,
  Flex,
  jsx,
  Link,
  Text,
  useThemeUI,
} from 'theme-ui';
import { layoutPadding } from '../Layout';
import { NovunaCFStackedWhiteLogo, NovunaPFStackedWhiteLogo } from '../Logos';
import { CompassDesignTheme } from '../../../themes';

interface BaseFooterProps {
  logo: React.ReactNode;
  companyText: string;
  tradingStyle: string;
}

const BaseFooter: React.FC<BaseFooterProps> = ({
  logo,
  companyText,
  tradingStyle,
}) => (
  <footer
    sx={{
      width: '100%',
      variant: 'layout.footer',
      fontSize: 0,
    }}
  >
    <Container p={layoutPadding}>
      <Box
        sx={{
          width: [103, null, 151],
          height: [23, null, 47],
          ml: 'auto',
        }}
      >
        {logo}
      </Box>
      <Flex
        sx={{
          flexWrap: 'wrap',
        }}
      >
        <Box mt={2}>
          <Link
            href='https://www.novunapersonalfinance.co.uk/terms-of-use'
            target='_blank'
            rel='noopener noreferrer'
          >
            Terms of use
          </Link>
        </Box>
        <Box mt={2} ml={4}>
          <Link
            href='https://www.novunapersonalfinance.co.uk/privacy-policy'
            target='_blank'
            rel='noopener noreferrer'
          >
            Privacy policy
          </Link>
        </Box>
        <Box mt={2} ml={4}>
          <Link
            href='https://www.novunapersonalfinance.co.uk/accessibility-policy'
            target='_blank'
            rel='noopener noreferrer'
          >
            Accessibility Policy
          </Link>
        </Box>
        <Box
          sx={{
            flex: ['0 1 100%', null, '0 1 auto'],
            ml: [null, null, 4],
          }}
          mt={2}
        >
          &copy; {new Date().getFullYear()} {companyText}
        </Box>
      </Flex>
      <Divider
        sx={{
          my: [3, null, 4],
        }}
      />
      <Text mt={0}>
        {tradingStyle}. Authorised and regulated by the Financial Conduct
        Authority. Financial Services Register no. 704348. The register can be
        accessed through{' '}
        <Link
          href='https://www.fca.org.uk/'
          target='_blank'
          rel='noopener noreferrer'
        >
          www.fca.org.uk
        </Link>
        .
      </Text>
      <Text mt={3}>
        Registered office: Novuna House, Thorpe Road, Staines-upon-Thames,
        Surrey, TW18 3HP. Registered in Cardiff under company no. 1630491.
      </Text>
      <Text mt={3} mb={0}>
        We are committed to protecting your personal information. You can manage
        your preferences and find out more in our privacy policy.
      </Text>
    </Container>
  </footer>
);

const parentCompany = 'Mitsubishi HC Capital UK PLC';

export const CM3Footer = () => (
  <BaseFooter
    logo={<NovunaCFStackedWhiteLogo />}
    companyText='Novuna Consumer Finance'
    tradingStyle={`Novuna Consumer Finance and CreditMaster3 are trading styles of ${parentCompany}`}
  />
);

export const PBFFooter = () => (
  <BaseFooter
    logo={<NovunaPFStackedWhiteLogo />}
    companyText='Novuna Personal Finance'
    tradingStyle={`Novuna Personal Finance and PaybyFinance are trading styles of ${parentCompany}`}
  />
);

export const PFFooter = () => {
  const {
    theme: { channel },
  } = useThemeUI() as CompassDesignTheme;

  const isBesavvi = channel === 'BESAVVI';
  const brand = isBesavvi ? 'besavvi' : 'Novuna Personal Finance';

  return (
    <BaseFooter
      logo={<NovunaPFStackedWhiteLogo />}
      companyText='Novuna Personal Finance'
      tradingStyle={`${brand} is a trading style of ${parentCompany}`}
    />
  );
};

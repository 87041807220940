/** @jsx jsx */
import React from 'react';
import { Box, BoxProps, Container, Flex, jsx, SxProps } from 'theme-ui';

export const layoutPadding = [3, null, 6];
const mtWithoutPageTop = [2, null, 3, null, 6];
const mtWithPageTop = 3;

export const SingleColumnLayout: React.FC<{ pageTop?: React.ReactNode }> = ({
  children,
  pageTop,
}) => (
  <Container
    mt={pageTop ? mtWithPageTop : mtWithoutPageTop}
    mb={[2, null, 4, 6]}
  >
    <Container as='main' px={[3, null, 6]}>
      {pageTop && (
        <Box as='aside' mb={[3, null, null, 4]}>
          {pageTop}
        </Box>
      )}
      <Box
        p={layoutPadding}
        backgroundColor='white'
        sx={{
          borderRadius: 'panel',
        }}
      >
        {children}
      </Box>
    </Container>
  </Container>
);

export const TwoColumnLayout: React.FC<
  React.PropsWithChildren<{
    sidebar: React.ReactNode;
    pageTop?: React.ReactNode;
    childrenContainerStyles?: SxProps['sx'];
  }>
> = ({ children, sidebar, pageTop, childrenContainerStyles = {} }) => (
  <Container
    mt={pageTop ? mtWithPageTop : mtWithoutPageTop}
    mb={[2, null, 4, 6]}
    px={[null, null, 3, 6]}
  >
    {pageTop && (
      <Box as='aside' mb={[3, null, null, 4]} px={[3, null, null, 0]}>
        {pageTop}
      </Box>
    )}
    <Flex
      sx={{
        flexFlow: 'row wrap',
      }}
    >
      <Container
        as='main'
        px={[2, null, 4, 0]}
        sx={{
          flex: ['1 100%', null, null, '1 0'],
        }}
      >
        <Box
          p={layoutPadding}
          backgroundColor='white'
          sx={{
            borderRadius: 'panel',
            ...childrenContainerStyles,
          }}
        >
          <Box sx={{ maxWidth: '620px' }}>{children}</Box>
        </Box>
      </Container>
      <Container
        as='aside'
        mt={[2, null, 4, 0]}
        ml={[null, null, null, 6]}
        px={[2, null, 4, 0]}
        sx={{
          maxWidth: [null, null, null, '396px'],
          flex: ['1 100%', null, null, '1 0'],
        }}
      >
        <Box
          p={layoutPadding}
          backgroundColor='white'
          sx={{
            borderRadius: 'panel',
            position: 'sticky',
            top: '0',
            zIndex: '0',
          }}
        >
          {sidebar}
        </Box>
      </Container>
    </Flex>
  </Container>
);

export const ResponsiveContainer: React.FC<
  React.PropsWithChildren<BoxProps>
> = ({ children, ...boxProps }) => (
  <Container my={[3, null, null, null]} px={[2, null, 4, 6]}>
    <Box {...boxProps}>{children}</Box>
  </Container>
);

import React, { useState } from 'react';
import { Container, Box, Flex, Link } from '../';

export interface AccessibilityBannerProps {
  uiType: 'fullWidth' | 'center';
}

export const AccessibilityBanner = ({ uiType }: AccessibilityBannerProps) => {
  const [dismissed, setDismissed] = useState(false);
  if (dismissed) return <></>;

  const NeedHelp = () => (
    <Link
      color='white'
      sx={{
        fontWeight: 'bold',
        '&:hover': { color: 'white', opacity: '0.9' },
      }}
      href='https://www.novunapersonalfinance.co.uk/accessibility-policy'
      target='_blank'
      aria-label='need help reading this page?'
      rel='noreferrer'
      data-test-id='compass-accessibility-banner-link'
      data-testid='compass-accessibility-banner-link'
    >
      Need help reading this?
    </Link>
  );

  const DismissBtn = () => (
    <Box
      color='white'
      sx={{
        fontWeight: 'bold',
        cursor: 'pointer',
        mr: '0.5em',
        '&:hover': { color: 'white', opacity: '0.9' },
      }}
      onClick={() => setDismissed(true)}
      data-test-id='compass-accessibility-banner-dismiss'
      data-testid='compass-accessibility-banner-dismiss'
    >
      X
    </Box>
  );

  switch (uiType) {
    case 'center':
      return (
        <Box bg={'secondaryPurple'} p={'0.25em 0.5em'}>
          <Container mb={null} px={[null, null, 3, 6]}>
            <Flex
              data-test-id='compass-accessibility-banner'
              data-testid='compass-accessibility-banner'
              sx={{ justifyContent: 'space-between' }}
            >
              <NeedHelp />
              <DismissBtn />
            </Flex>
          </Container>
        </Box>
      );

    case 'fullWidth':
      return (
        <Box bg={'secondaryPurple'} p={'0.25em 0.5em'}>
          <Flex
            data-test-id='compass-accessibility-banner'
            data-testid='compass-accessibility-banner'
            sx={{ justifyContent: 'space-between' }}
          >
            <NeedHelp />
            <DismissBtn />
          </Flex>
        </Box>
      );

    default:
      return <></>;
  }
};

export default AccessibilityBanner;

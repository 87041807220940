import { darken, alpha } from '@theme-ui/color';
import { Theme } from 'theme-ui';
import { pxToRem } from '../components';

// Make it an `any` for now.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type CompassDesignTheme = any;

export const abstractTheme = {
  space: [
    // in px
    0, // size 0
    4, // 1
    8, // 2
    16, // 3
    24, // 4
    32, // 5
    40, // 6
  ],
  radii: {
    alert: '4px',
    button: '8px',
    panel: '16px',
  },
  borderWidths: [
    // in px
    0, // size 0
    4, // 1
    8, // 2
    16, // 3
    24, // 4
    32, // 5
    40, // 6
  ],
  shadows: {
    button: '0px 3px 11px 0px rgba(0,0,0,0.18)',
    panel: '0 8px 24px 0 rgba(0, 0, 0, 0.16)',
  },
  fonts: {
    body: '"Helvetica Neue", sans-serif',
    heading: 'inherit',
    monospace: 'Menlo, monospace',
  },
  layout: {
    footer: {
      maxWidth: '1200px',
    },
    navbar: {
      maxWidth: '1200px',
    },
    footerContent: {
      maxWidth: '800px',
    },
  },
  fontSizes: pxToRem([
    // in px
    12, // size 0
    16, // 1
    20, // 2
    24, // 3
    32, // 4
    40, // 5
  ]),
  sizes: {
    modal: {
      close: '48px',
      padding: '1.5rem',
      headingFontSize: {
        small: '3',
        medium: '4',
        large: '4',
      },
      width: {
        small: '25em',
        medium: '45em',
        large: '100vw', // larger than maxWidth
      },
      minHeight: '10em',
    },
  },
  fontWeights: {
    body: 400,
    heading: 300,
    bold: 700,
  },
  lineHeights: {
    body: 1.2,
    heading: 1.125,
  },
  textStyles: {
    heading: {
      lineHeight: 1.125,
    },
  },
  headings: {
    underlineMargin: '0.48em',
  },
  text: { heading: { lineHeight: 1.125 } },
  colors: {
    text: '#1a1a1a',
    background: '#fff',
    secondary: '#007393',
    muted: '#f2f2f2',
    highlight: '#fd0014',
    info: '#f2f8fa',
    monochrome: [
      '#000000',
      '#1a1a1a',
      '#4d4d4d',
      '#737373',
      '#e6e6e6',
      '#f2f2f2',
    ],
    tone: {
      neutral: '#1a1a1a',
      positive: '#228725',
      negative: '#ef0000',
      background: {
        neutral: 'transparent',
        positive: 'transparent',
        negative: 'transparent',
      },
    },
  },
  forms: {
    input: {
      borderWidth: '2px',
      borderRadius: '8px',
      fontFamily: 'body',
      fontSize: 2,
      outline: 'none',
      paddingLeft: 3,
      '&:focus': {
        borderColor: 'monochrome.1',
        boxShadow: 'inset 0 0 0 2px',
      },
      '::placeholder': {
        color: 'monochrome.3',
      },
    },
    textarea: {
      borderWidth: '2px',
      borderRadius: '8px',
      fontFamily: 'body',
      fontSize: 2,
      outline: 'none',
      paddingLeft: 3,
      '&:focus': {
        borderColor: 'monochrome.1',
        boxShadow: 'inset 0 0 0 2px',
      },
      '::placeholder': {
        color: 'monochrome.3',
      },
    },
    select: {
      borderWidth: '2px',
      borderRadius: '8px',
      fontFamily: 'body',
      fontSize: 2,
      outline: 'none',
      '&:focus': {
        borderColor: 'monochrome.1',
        boxShadow: 'inset 0 0 0 2px',
      },
      'option[value=""]:first-child': {
        color: 'monochrome.3',
      },
      'option:not(:first-child)': {
        color: 'monochrome.1',
      },
      width: 'auto',
      paddingRight: 'calc(1em + 8px)',
    },
    prefix: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      left: '3',
      fontSize: 2,
    },
    radio: {
      color: 'monochrome.1',
      height: '32px',
      width: '32px',
      'input:focus ~ &': {
        backgroundColor: 'monochrome.4',
      },
      'input:checked ~ &': { color: 'monochrome.1' },
    },
    checkbox: {
      position: 'absolute',
      left: '-9999px',
    },
  },
  button: {
    borderRadius: 'button',
    fontFamily: 'body',
    fontSize: 3,
    lineHeight: 1.167,
    paddingRight: '10px',
    boxShadow: 'button',
    cursor: 'pointer',
    '&:active': {
      boxShadow: 'none',
    },
    // disabled class for <a>s which don't support the disabled attribute
    '&:disabled, &.disabled': {
      boxShadow: 'none',
      cursor: 'default',
    },
    minHeight: '48px',
    outline: 'none',
    verticalAlign: 'middle',
  },
  checkbox: {
    glow: {
      padding: 2,
      minHeight: '48px',
      maxHeight: '48px',
      minWidth: '48px',
      maxWidth: '48px',
      borderRadius: (theme: CompassDesignTheme) =>
        `calc(${theme.checkbox.glow.padding}px + ${theme.radii.button})`,
      '&:hover': {
        backgroundColor: 'monochrome.4',
      },
    },
    box: {
      minWidth: '32px',
      maxWidth: '32px',
      minHeight: '32px',
      maxHeight: '32px',
      borderStyle: 'solid',
      borderRadius: 'button',
      borderWidth: '2px',
    },
  },
  modal: {
    mask: {
      backgroundColor: 'rgba(26, 26, 26, 0.64)',
    },
    header: {
      padding: (t: CompassDesignTheme) => t.sizes.modal.padding,
    },
    panel: {
      backgroundColor: 'background',
      boxShadow: 'panel',
      borderRadius: 'panel',
      minHeight: 'modal.minHeight',
      maxHeight: (t: CompassDesignTheme) =>
        `calc(100vh - 2 * ${t.radii.panel})`,
      maxWidth: (t: CompassDesignTheme) => `calc(100vw - 2 * ${t.radii.panel})`,

      display: 'flex',
      flexDirection: 'column',
    },
    close: {
      top: '3',
      right: '3',
      color: 'monochrome.3',
      height: 'modal.close',
      width: 'modal.close',
      svg: {
        height: 'modal.close',
        width: 'modal.close',
      },
    },
    content: {
      padding: (t: CompassDesignTheme) =>
        `0 ${t.sizes.modal.padding} ${t.sizes.modal.padding}`,
      marginBottom: (t: CompassDesignTheme) => t.radii.panel,
    },
  },
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
    },
    h1: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 5,
    },
    h2: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 4,
    },
    h3: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 3,
    },
    h4: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 2,
    },
    h5: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 1,
    },
    h6: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 1,
    },
    p: {
      color: 'text',
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
    },
    pre: {
      fontFamily: 'monospace',
      overflowX: 'auto',
      code: {
        color: 'inherit',
      },
    },
    code: {
      fontFamily: 'monospace',
      fontSize: 'inherit',
    },
    table: {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0,
    },
    th: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
    td: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
    img: {
      maxWidth: '100%',
    },
    a: {
      color: 'secondary',
    },
  },
  cards: {
    primary: {
      borderRadius: 'panel',
      boxShadow: 'panel',
      border: '2px solid',
      borderColor: 'secondary',
    },
    secondary: {
      borderRadius: 'panel',
      boxShadow: 'panel',
    },
    disabled: {
      borderRadius: 'panel',
      backgroundColor: 'muted',
      boxShadow: 'none',
      color: 'monochrome.3',
    },
  },
  badges: {
    primary: {
      color: '#259b2a',
      bg: alpha('#1d9d27', 0.1),
      height: '22px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  buttons: {
    primary: {
      '&:hover, &:focus': {
        bg: darken('primary', 0.08),
      },
    },
    secondary: {
      '&:hover, &:focus': {
        bg: darken('secondary', 0.08),
      },
    },
    text: {
      backgroundColor: 'transparent',
      color: 'secondary',
      textDecoration: 'underline',
      cursor: 'pointer',
      padding: 0,
      outline: 'none',
      '&:hover, &:focus': {
        backgroundColor: 'transparent',
      },
    },
  },
} as unknown as Theme;

/**  @jsx jsx */
import React from 'react';
import { jsx } from 'theme-ui';

type PlaceholderProps = {
  wide?: boolean;
};

export const Placeholder: React.FC<PlaceholderProps> = ({ wide }) => (
  <div
    sx={{
      border: 'none',
      borderRadius: '12px',
      backgroundColor: 'monochrome.5',
      height: '24px',
      width: wide ? '120px' : '100px',
    }}
  />
);

import { merge, Theme } from 'theme-ui';
import { cm3Theme } from './cm3Theme';

export const legacyCm3Theme = merge(cm3Theme, {
  useBodyStyles: false,
  useBorderBox: false,
  styles: {
    a: {
      // Undo underline definition from parent theme
      textDecoration: undefined,
    },
  },
} as unknown as Theme);

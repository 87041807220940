import React from 'react';
import { Label } from 'theme-ui';

interface ScreenReaderLabelProps {
  label: string;
  id?: string;
}

export const ScreenReaderLabel: React.FC<ScreenReaderLabelProps> = ({
  label,
  id,
}) => {
  return (
    <>
      <Label
        htmlFor={id}
        sx={{
          clip: 'rect(0 0 0 0)',
          clipPath: 'inset(50%)',
          height: '1px',
          overflow: 'hidden',
          position: 'absolute',
          whiteSpace: 'nowrap',
          width: '1px',
        }}
      >
        {label}
      </Label>
    </>
  );
};

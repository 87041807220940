/** @jsx jsx */
import { useEffect } from 'react';

interface UseOutsideCloser {
  ref: any;
  closeMenu: () => void;
}

const useOutsideCloser = ({ ref, closeMenu }: UseOutsideCloser) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target)) {
        closeMenu();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', (event: MouseEvent) =>
      handleClickOutside(event)
    );
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', (event: MouseEvent) =>
        handleClickOutside(event)
      );
    };
  }, [ref, closeMenu]);
};

export default useOutsideCloser;

/** @jsx jsx */
import { Box, jsx, Text } from 'theme-ui';
import { NovunaHeading } from '../Novuna';

interface AccordionContentProps {
  title: string;
  hint?: string;
  description: string;
  descriptionTestId?: string;
}

export const AccordionContent = ({
  title,
  hint,
  description,
  descriptionTestId,
}: AccordionContentProps) => (
  <Box mb={4}>
    <NovunaHeading as='h3' mb={2}>
      {title}
    </NovunaHeading>
    {hint && (
      <Text mb={2} sx={{ color: 'monochrome.3' }}>
        {hint}
      </Text>
    )}
    <Text data-test-id={descriptionTestId ?? ''}>{description}</Text>
  </Box>
);

import { merge, Theme } from 'theme-ui';
import { abstractTheme } from './abstractTheme';
import { darken } from '@theme-ui/color';

export const retailTheme = merge(abstractTheme, {
  breakpoints: ['600px', '768px', '1280px'],
  sizes: {
    container: '632px',
  },
  colors: {
    primary: '#b1000e',
    tints: [],
  },
  styles: {
    a: {
      // To counteract a global style in CM3
      textDecoration: 'underline',
    },
  },
  buttons: {
    quaternary: {
      borderStyle: 'solid',
      borderColor: 'muted',
      borderRadius: '24px',
      backgroundColor: 'muted',
      color: 'monochrome.3',
      '&:hover, &:focus': {
        borderColor: darken('muted', 0.08),
        bg: darken('muted', 0.08),
      },
    },
    tertiary: {
      borderWidth: '2px',
      boxSizing: 'border-box',
      borderStyle: 'solid',
      borderColor: 'secondary',
      backgroundColor: 'background',
      color: 'secondary',
      '&:hover, &:focus': {
        color: darken('secondary', 0.08),
        borderColor: darken('secondary', 0.08),
      },
    },
    secondary: {
      borderStyle: 'solid',
      borderColor: 'secondary',
      backgroundColor: 'secondary',
      color: 'background',
      '&:hover, &:focus': {
        borderColor: darken('secondary', 0.08),
        bg: darken('secondary', 0.08),
      },
    },
  },
  channel: 'PBF',
} as unknown as Theme);

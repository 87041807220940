/* eslint-disable react/prop-types */
/** @jsx jsx */
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react';
import { Box, Flex, jsx, Text } from 'theme-ui';
import { TextButton } from '../TextButton';
import { formatId } from '../utils';
import { AccordionEntry, AccordionEntryProps } from './AccordionEntry';

interface AccordionGroupProps {
  headingAs: AccordionEntryProps['headingAs'];
  label?: string;
  testIdPrefix?: string;
}

type AccordionContext = {
  headingAs: AccordionGroupProps['headingAs'];
  sectionsOpen: Record<string, boolean>;
  openSection: (update: { [section: string]: boolean }) => void;
  testIdPrefix: AccordionGroupProps['testIdPrefix'];
};
const Context = createContext<AccordionContext>({} as AccordionContext);

export const AccordionGroup: React.FunctionComponent<AccordionGroupProps> = ({
  headingAs,
  children,
  label,
  testIdPrefix = 'accordion-group',
}) => {
  const [sectionsOpen, openSection] = useReducer(
    (
      currentlyOpen: AccordionContext['sectionsOpen'],
      update: Parameters<AccordionContext['openSection']>[0]
    ) => ({
      ...currentlyOpen,
      ...update,
    }),
    {} as AccordionContext['sectionsOpen']
  );

  const allOpen = useMemo(
    () =>
      Object.values(sectionsOpen).reduce(
        (all, section) => all && section,
        true
      ),
    [sectionsOpen]
  );

  const setAll = useCallback(() => {
    openSection(
      Object.keys(sectionsOpen).reduce(
        (all, section) => ({ ...all, [section]: !allOpen }),
        {}
      )
    );
  }, [sectionsOpen, allOpen]);

  return (
    <Context.Provider
      value={{
        headingAs,
        sectionsOpen,
        openSection,
        testIdPrefix,
      }}
    >
      <Flex
        mb={3}
        sx={{ justifyContent: label ? 'space-between' : 'flex-end' }}
      >
        {label && (
          <Text data-test-id={formatId('heading', { prefix: testIdPrefix })}>
            {label}
          </Text>
        )}
        <TextButton
          data-test-id={formatId('toggle-all', { prefix: testIdPrefix })}
          onClick={setAll}
          sx={{
            color: 'secondaryPurple',
            textDecoration: 'underline',
            backgroundColor: 'inherit',
            p: 0,
            m: 0,
            cursor: 'pointer',
            '@media print': {
              display: 'none',
            },
          }}
        >
          {allOpen ? 'Close all' : 'Open all'}
        </TextButton>
      </Flex>
      <Box>{children}</Box>
    </Context.Provider>
  );
};

interface AccordionGroupEntryProps {
  sectionNumber: AccordionEntryProps['sectionNumber'];
  title: AccordionEntryProps['title'];
  description: AccordionEntryProps['description'];
  initiallyOpen?: boolean;
  headingVariant?: string;
  breakAfter?: boolean;
}

export const AccordionGroupEntry: React.FunctionComponent<
  AccordionGroupEntryProps
> = ({
  sectionNumber,
  initiallyOpen = false,
  headingVariant = 'secondary',
  breakAfter = false,
  ...props
}) => {
  const { headingAs, sectionsOpen, openSection, testIdPrefix } =
    useContext(Context);
  const initiallyOpenRef = useRef(initiallyOpen); // use a ref to grab the initial value of the prop

  const isOpen = useMemo(() => {
    if (Object.prototype.hasOwnProperty.call(sectionsOpen, sectionNumber)) {
      return sectionsOpen[sectionNumber];
    } else {
      return initiallyOpenRef.current;
    }
  }, [sectionNumber, sectionsOpen]);

  const setOpen = useCallback(
    (open: boolean) => {
      openSection({ [sectionNumber]: open });
    },
    [openSection, sectionNumber]
  );

  useEffect(
    () => {
      // initialise the parent context on mount
      setOpen(initiallyOpenRef.current);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <AccordionEntry
      {...props}
      sectionNumber={sectionNumber}
      headingAs={headingAs}
      headingVariant={headingVariant}
      isOpen={isOpen}
      setOpen={setOpen}
      breakAfter={breakAfter}
      testIdPrefix={testIdPrefix}
    />
  );
};

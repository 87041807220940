/** @jsx jsx */
import React from 'react';
import { Flex, FlexProps, jsx, Text } from 'theme-ui';
import { RedAlert } from '../../Novuna/';

interface ErrorMessageProps extends FlexProps {
  error?: string;
}

export const ErrorMessage = React.forwardRef<
  HTMLDivElement,
  React.PropsWithoutRef<ErrorMessageProps>
>(({ error, ...rest }, ref) => (
  <Flex {...rest} sx={{ alignItems: 'flex-start' }} ref={ref}>
    <RedAlert />
    <Text ml={2} sx={{ flex: 1, color: 'invalidRed', whiteSpace: 'pre-line' }}>
      {error}
    </Text>
  </Flex>
));

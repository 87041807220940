import React from 'react';

interface PasswordStrengthBarProps {
  strength: number;
  mb?: string;
}

export const PasswordStrengthBar: React.FunctionComponent<
  PasswordStrengthBarProps
> = ({ strength, mb }) => {
  const getStrengthColorByIndex = (index: number) => {
    if (strength === 1 && index === 0) {
      return '#c60073';
    }
    if (strength === 2 && index < 2) {
      return '#fca647';
    }
    if (strength === 3 && index < 3) {
      return '#d7ffd7';
    }
    if (strength === 4 && index < 4) {
      return '#71DC99';
    }
    return 'lightgrey';
  };

  const strengthMeterStyle: React.CSSProperties = {
    display: 'flex',
    height: '5px',
    marginTop: '10px',
    borderRadius: '3px',
    marginBottom: mb,
  };

  const strengthMeterFill: React.CSSProperties = {
    height: '100%',
    flex: 1,
    transition: 'background 0.5s ease-in-out',
  };

  return (
    <div style={strengthMeterStyle}>
      {[0, 1, 2, 3].map((index) => (
        <div
          key={index}
          style={{
            ...strengthMeterFill,
            background: getStrengthColorByIndex(index),
            marginRight: index === 3 ? '0' : '10px',
          }}
          data-strength={index}
        ></div>
      ))}
    </div>
  );
};

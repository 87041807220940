/* eslint-disable react/prop-types */
/** @jsx jsx */
import React from 'react';
import { Box, InputProps, jsx, Text } from 'theme-ui';
import { darken } from '@theme-ui/color';

interface RadioButtonProps extends InputProps {
  checked?: boolean;
}

const StyledButton = (props: any) => (
  <React.Fragment>
    <Box
      {...props}
      variant='buttons.secondary'
      sx={{
        boxShadow: 'none',
        borderRadius: '28px',
        cursor: 'pointer',
        paddingTop: '15px',
        paddingBottom: '17px',
        paddingX: 4,
        justifyContent: 'center',
        display: 'none',
        'input:checked ~ &': {
          display: 'block',
        },
        'input:focus ~ &': {
          borderColor: darken('secondary', 0.08),
          bg: darken('secondary', 0.08),
        },
      }}
    ></Box>
    <Box
      {...props}
      variant='buttons.quaternary'
      sx={{
        boxShadow: 'none',
        borderRadius: '28px',
        cursor: 'pointer',
        paddingTop: '15px',
        paddingBottom: '17px',
        paddingX: 4,
        justifyContent: 'center',
        display: 'block',
        'input:checked ~ &': {
          display: 'none',
        },
        'input:focus ~ &': {
          borderColor: darken('muted', 0.08),
          bg: darken('muted', 0.08),
        },
      }}
    ></Box>
  </React.Fragment>
);

export const RadioButton = React.forwardRef<HTMLInputElement, RadioButtonProps>(
  ({ children, id, ...props }, ref) => {
    return (
      <Box>
        <Box
          data-test-id={`tailoring-option-${id}`}
          ref={ref}
          as='input'
          type='radio'
          {...props}
          sx={{
            position: 'absolute',
            opacity: 0,
            zIndex: -1,
            width: 1,
            height: 1,
            overflow: 'hidden',
          }}
        />
        <Box as={StyledButton} aria-hidden='true' mr={2}>
          <Text sx={{ fontSize: 1, fontWeight: 'bold' }}>{children}</Text>
        </Box>
      </Box>
    );
  }
);

/* eslint-disable react/prop-types */
/** @jsx jsx */
import React, { Fragment } from 'react';
import { InputProps, Box, Input, Text, jsx, Label } from 'theme-ui';
import { Tick } from '../../Icons';
import { darken } from '@theme-ui/color';

interface CheckboxButtonProps extends InputProps {
  checked?: boolean;
  customSx?: object;
}

export const CheckboxButtonRetail = React.forwardRef<
  HTMLInputElement,
  CheckboxButtonProps
>(({ children, id, checked = false, customSx, ...rest }, ref) => {
  const checkedSx = {
    backgroundColor: darken('secondary', 0.08),
    borderColor: darken('secondary', 0.08),
    color: 'background',
  };

  const uncheckedSx = {
    borderColor: darken('secondary', 0.08),
    color: darken('secondary', 0.08),
  };

  return (
    <Fragment>
      <Input
        {...rest}
        ref={ref}
        id={id}
        variant='checkbox'
        type='checkbox'
        checked={checked}
        sx={{
          '&:focus + label': checked ? checkedSx : uncheckedSx,
          '&:focus + label > div': {
            borderColor: checked ? 'background' : darken('secondary', 0.08),
          },
        }}
      />
      <Label
        htmlFor={id}
        sx={{
          variant: 'button',
          minWidth: '139px',
          padding: 2,
          display: 'flex',
          '&:hover > :first-child': {
            color: darken('secondary', 0.08),
            borderColor: checked ? 'background' : darken('secondary', 0.08),
          },
          border: '2px solid',
          borderColor: 'secondary',
          ...customSx,
        }}
        variant={checked ? 'buttons.secondary' : 'buttons.tertiary'}
      >
        <Box
          sx={{
            variant: 'checkbox.box',
            backgroundColor: 'background',
            color: 'secondary',
            borderColor: checked ? 'background' : 'secondary',
          }}
        >
          {checked && <Tick />}
        </Box>
        <Text ml={3} sx={{ fontSize: 3 }} paddingTop='0.1em'>
          {children}
        </Text>
      </Label>
    </Fragment>
  );
});

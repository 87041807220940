import React from 'react';
import { Box, Input, InputProps } from 'theme-ui';
import { Button } from '../../Button';
import { NovunaCross } from '../../Novuna';
import { SectionError } from '../../SectionError';

interface SearchBarProps extends InputProps {
  buttonText: string;
  query: string;
  clearSearch: () => void;
  touched?: boolean;
  error?: string;
}

const SearchBar = React.forwardRef<HTMLInputElement, SearchBarProps>(
  (props, ref) => {
    const {
      name,
      touched,
      error,
      buttonText,
      query,
      onChange,
      onClick,
      clearSearch,
      ...rest
    } = props;

    return (
      <>
        {touched && error && (
          <SectionError className='compass-field-error-message'>
            {error}
          </SectionError>
        )}
        <Box
          sx={{
            display: ['block', 'block', 'flex', 'flex'],
            position: 'relative',
            maxWidth: '546px',
          }}
        >
          <Input
            {...rest}
            data-test-id='search-input'
            ref={ref}
            name={name}
            sx={{
              width: ['100%', '100%', '70%', '80%'],
              display: 'inline-block',
              height: '48px',
              verticalAlign: 'middle',
              borderColor: '#999',
              borderRightWidth: ['2px', '2px', '1px', '1px'],
              borderRadius: ['5px', '5px', '5px 0 0 5px', '5px 0 0 5px'],
              position: 'relative',
            }}
            value={query}
            onChange={onChange}
          />
          {query.length > 0 && (
            <Button
              data-test-id='search-clear'
              sx={{
                right: ['3%', '3%', '32%', '22%'],
                top: '10px',
                bottom: '0',
              }}
              type='reset'
              variant='clear'
              onClick={clearSearch}
            >
              <NovunaCross></NovunaCross>
            </Button>
          )}

          <Button
            data-test-id='search-submit'
            mt={[2, null, 0, 0]}
            variant='attached'
            type='submit'
          >
            {buttonText}
          </Button>
        </Box>
      </>
    );
  }
);

SearchBar.displayName = 'SearchBar';

export default SearchBar;

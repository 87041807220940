/** @jsx jsx */
import React from 'react';
import {
  Heading as BaseHeading,
  HeadingProps as BaseHeadingProps,
  jsx,
} from 'theme-ui';

export type HeadingProps = BaseHeadingProps;

export const Heading: React.FC<HeadingProps> = (props) => (
  <BaseHeading {...props} variant={`styles.${props.as ?? 'h2'}`} />
);

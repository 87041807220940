/** @jsx jsx */
import React, { Children } from 'react';
import { Grid, GridProps, jsx, SxStyleProp, useThemeUI } from 'theme-ui';
import { CompassDesignTheme } from '../../../themes';

type TableHeadingRowProps = GridProps;

export const TableHeadingRow = React.forwardRef<
  HTMLDivElement,
  TableHeadingRowProps
>((props, ref) => {
  const {
    theme: { channel },
  } = useThemeUI() as CompassDesignTheme;
  const isNovuna = channel === 'NOVUNA';

  let sx: SxStyleProp = {
    fontSize: 0,
    fontWeight: 'bold',
  };
  if (isNovuna) {
    sx = {
      ...sx,
      padding: 3,
      color: 'tints.1',
      backgroundColor: '#f4f5f6',
      textTransform: 'uppercase',
      letterSpacing: '0.5px',
      ...props.sx,
    };
  } else {
    sx = {
      ...sx,
      paddingTop: 4,
      paddingBottom: 3,
      paddingLeft: 3,
      borderBottom: '2px solid',
      borderColor: 'monochrome.2',
      ...props.sx,
    };
  }

  const { children } = props;

  return (
    <Grid columns={Children.count(children)} ref={ref} sx={sx}>
      {children}
    </Grid>
  );
});

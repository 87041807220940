/** @jsx jsx */
import React, { Fragment } from 'react';
import { jsx, Box, Button } from 'theme-ui';
import { NovunaArrowLeft, NovunaArrowRight } from '../Novuna';

const defaultSectionStyle = {
  display: 'flex',
  width: '48px',
  height: '48px',
  justifyContent: 'center',
  alignItems: 'center',
};

interface PageButtonProps {
  currentPage: number;
  pageToDisplay: number;
  selectPage: (selectedPage: number) => void;
}

const PageButton: React.FC<PageButtonProps> = ({
  currentPage,
  pageToDisplay,
  selectPage,
}) => {
  const isSelected = pageToDisplay === currentPage;
  return (
    <Button
      data-test-id={`pagination-page-${pageToDisplay}`}
      variant={isSelected ? 'paginationSelected' : 'pagination'}
      onClick={() => (isSelected ? null : selectPage(pageToDisplay))}
    >
      {pageToDisplay}
    </Button>
  );
};

const MissingPagesIndicator: React.FC = () => (
  <Box sx={defaultSectionStyle}>...</Box>
);

const FirstPageWithMissingPagesIndicator: React.FC<
  Omit<PageButtonProps, 'pageToDisplay'>
> = ({ currentPage, selectPage }) => (
  <Fragment>
    <PageButton
      currentPage={currentPage}
      pageToDisplay={1}
      selectPage={selectPage}
    />
    <MissingPagesIndicator />
  </Fragment>
);

const LastPageWithMissingPagesIndicator: React.FC<PageButtonProps> = ({
  currentPage,
  pageToDisplay,
  selectPage,
}) => (
  <Fragment>
    <MissingPagesIndicator />
    <PageButton
      currentPage={currentPage}
      pageToDisplay={pageToDisplay}
      selectPage={selectPage}
    />
  </Fragment>
);

const MiddlePages: React.FC<Omit<PageButtonProps, 'pageToDisplay'>> = ({
  currentPage,
  selectPage,
}) => (
  <Fragment>
    <PageButton
      currentPage={currentPage}
      pageToDisplay={currentPage - 1}
      selectPage={selectPage}
    />
    <PageButton
      currentPage={currentPage}
      pageToDisplay={currentPage}
      selectPage={selectPage}
    />
    <PageButton
      currentPage={currentPage}
      pageToDisplay={currentPage + 1}
      selectPage={selectPage}
    />
  </Fragment>
);

interface PaginationProps extends Omit<PageButtonProps, 'pageToDisplay'> {
  totalNumberOfPages: number;
  [key: string]: unknown;
}

export const Pagination: React.FC<PaginationProps> = ({
  totalNumberOfPages,
  selectPage,
  currentPage,
}) => {
  const pageButtons = [];

  if (totalNumberOfPages < 8) {
    for (let i = 1; i < totalNumberOfPages + 1; i++) {
      pageButtons.push(
        <PageButton
          currentPage={currentPage}
          pageToDisplay={i}
          selectPage={selectPage}
        />
      );
    }
  } else {
    if (currentPage < 5) {
      for (let i = 1; i < 6; i++) {
        pageButtons.push(
          <PageButton
            currentPage={currentPage}
            pageToDisplay={i}
            selectPage={selectPage}
          />
        );
      }
      pageButtons.push(
        <LastPageWithMissingPagesIndicator
          currentPage={currentPage}
          pageToDisplay={totalNumberOfPages}
          selectPage={selectPage}
        />
      );
    } else if (currentPage > totalNumberOfPages - 4) {
      pageButtons.push(
        <FirstPageWithMissingPagesIndicator
          currentPage={currentPage}
          selectPage={selectPage}
        />
      );

      for (let i = totalNumberOfPages - 4; i < totalNumberOfPages + 1; i++) {
        pageButtons.push(
          <PageButton
            currentPage={currentPage}
            pageToDisplay={i}
            selectPage={selectPage}
          />
        );
      }
    } else {
      pageButtons.push(
        <FirstPageWithMissingPagesIndicator
          currentPage={currentPage}
          selectPage={selectPage}
        />
      );
      pageButtons.push(
        <MiddlePages currentPage={currentPage} selectPage={selectPage} />
      );
      pageButtons.push(
        <LastPageWithMissingPagesIndicator
          currentPage={currentPage}
          pageToDisplay={totalNumberOfPages}
          selectPage={selectPage}
        />
      );
    }
  }

  const numberOfButtons = Math.min(totalNumberOfPages, 7) + 2;
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === totalNumberOfPages;

  return (
    <Fragment>
      <Box
        sx={{
          display: ['grid', null, 'none'],
          width: '160px',
          mx: 'auto',
          gap: 2,
          gridTemplateColumns: `repeat(${numberOfButtons}, 48px)`,
        }}
      >
        <Button
          data-test-id='pagination-page-back-mobile'
          variant='paginationDirection'
          sx={{
            ...defaultSectionStyle,
            opacity: isFirstPage ? 0.4 : 1,
            cursor: isFirstPage ? 'default' : 'pointer',
          }}
          onClick={() => {
            if (!isFirstPage) {
              selectPage(currentPage - 1);
            }
          }}
        >
          <NovunaArrowLeft />
        </Button>
        <PageButton
          currentPage={currentPage}
          pageToDisplay={currentPage}
          selectPage={selectPage}
        />
        <Button
          data-test-id='pagination-page-forward-mobile'
          variant='paginationDirection'
          sx={{
            ...defaultSectionStyle,
            opacity: isLastPage ? 0.4 : 1,
            cursor: isLastPage ? 'default' : 'pointer',
            paddingTop: '10px',
            paddingLeft: '10px',
          }}
          onClick={() => {
            if (!isLastPage) {
              selectPage(currentPage + 1);
            }
          }}
        >
          <NovunaArrowRight />
        </Button>
      </Box>
      <Box
        sx={{
          display: ['none', null, 'grid'],
          width: `${48 * numberOfButtons + (numberOfButtons - 1) * 8}px`,
          mx: 'auto',
          gap: 2,
          gridTemplateColumns: `repeat(${numberOfButtons}, 48px)`,
        }}
      >
        <Button
          data-test-id='pagination-page-back-desktop'
          variant='paginationDirection'
          sx={{
            ...defaultSectionStyle,
            opacity: isFirstPage ? 0.4 : 1,
            cursor: isFirstPage ? 'default' : 'pointer',
          }}
          onClick={() => {
            if (!isFirstPage) {
              selectPage(currentPage - 1);
            }
          }}
        >
          <NovunaArrowLeft />
        </Button>
        {pageButtons.map((pageButton, i) => (
          <Fragment key={i}>{pageButton}</Fragment>
        ))}
        <Button
          data-test-id='pagination-page-forward-desktop'
          variant='paginationDirection'
          sx={{
            ...defaultSectionStyle,
            opacity: isLastPage ? 0.4 : 1,
            cursor: isLastPage ? 'default' : 'pointer',
            paddingTop: '10px',
            paddingLeft: '10px',
          }}
          onClick={() => {
            if (!isLastPage) {
              selectPage(currentPage + 1);
            }
          }}
        >
          <NovunaArrowRight />
        </Button>
      </Box>
    </Fragment>
  );
};

import { Card as BaseCard, CardProps as BaseCardProps } from 'theme-ui';
import React, { ReactNode } from 'react';

export interface CardProps extends BaseCardProps {
  button?: ReactNode;
  [key: string]: unknown;
}

export const Card: React.FunctionComponent<CardProps> = ({
  ref,
  button,
  children,
  ...props
}) => (
  <BaseCard {...props}>
    {children}
    {button}
  </BaseCard>
);

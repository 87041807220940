/** @jsx jsx */
import { jsx } from 'theme-ui';
import { ReactComponent as AddNoteRaw } from './AddNote.svg';
import { ReactComponent as ContinueRaw } from './Continue.svg';
import { ReactComponent as ArrowRightRaw } from './ArrowRight.svg';
import { ReactComponent as ArrowLeftRaw } from './ArrowLeft.svg';
import { ReactComponent as TickOutlineRaw } from './TickOutline.svg';
import { ReactComponent as TickCircleRaw } from './TickCircle.svg';
import { ReactComponent as AlertCircleRaw } from './AlertCircle.svg';
import { ReactComponent as AlertOutlineRaw } from './AlertOutline.svg';
import { ReactComponent as SearchRaw } from './Search.svg';
import { ReactComponent as SearchOutlineRaw } from './SearchOutline.svg';
import { ReactComponent as LaunchCircleRaw } from './LaunchCircle.svg';
import { ReactComponent as TickRaw } from './Tick.svg';
import { ReactComponent as DownloadCircleRaw } from './DownloadCircle.svg';
import { ReactComponent as PrintCircleRaw } from './PrintCircle.svg';
import { ReactComponent as ClockRaw } from './Clock.svg';
import { ReactComponent as PadlockRaw } from './Padlock.svg';
import { ReactComponent as FiveRaw } from './Five.svg';
import { ReactComponent as RetryRaw } from './Retry.svg';
import { ReactComponent as ExitRaw } from './Exit.svg';
import { ReactComponent as SendCircleRaw } from './SendCircle.svg';
import { ReactComponent as InfoOutlineRaw } from './InfoOutline.svg';
import { ReactComponent as SoftSearchWithTickRaw } from './SoftSearchWithTick.svg';
import { Tone } from '../Input';

interface InfoOutlineProps {
  size?: number;
  color?: string;
}

export const InfoOutline = ({
  size = 40,
  color = '#007393',
}: InfoOutlineProps) => (
  <InfoOutlineRaw
    width={size}
    height={size}
    sx={{
      color,
      verticalAlign: 'top',
    }}
  />
);

export const Search = () => (
  <SearchRaw
    width={28}
    height={28}
    sx={{
      fill: 'currentColor',
      verticalAlign: 'top',
      marginLeft: 4,
    }}
  />
);

interface SearchOutlineProps {
  ml?: string | number;
}

export const SearchOutline = ({ ml = '4' }: SearchOutlineProps) => (
  <SearchOutlineRaw
    width={28}
    height={28}
    sx={{
      fill: 'currentColor',
      verticalAlign: 'top',
      marginLeft: ml,
    }}
  />
);

export const Continue = () => (
  <ContinueRaw
    width={26}
    height={26}
    sx={{
      fill: 'currentColor',
      verticalAlign: 'top',
      marginLeft: 4,
    }}
  />
);

export const AddNote = () => (
  <AddNoteRaw
    width={28}
    height={28}
    sx={{
      fill: 'currentColor',
      verticalAlign: 'top',
      marginLeft: 4,
    }}
  />
);

export const Retry = () => (
  <RetryRaw
    width={26}
    height={26}
    sx={{
      fill: 'currentColor',
      verticalAlign: 'top',
      marginLeft: 4,
    }}
  />
);

export const Exit = () => (
  <ExitRaw
    width={26}
    height={26}
    sx={{
      fill: 'currentColor',
      verticalAlign: 'top',
      marginLeft: 4,
    }}
  />
);

export const ArrowRight = () => (
  <ArrowRightRaw
    width={26}
    height={26}
    sx={{
      fill: 'currentColor',
      verticalAlign: 'top',
    }}
  />
);

export const ArrowLeft = () => (
  <ArrowLeftRaw
    width={20}
    height={20}
    sx={{
      // color: 'secondary',
      fill: 'secondary',
      verticalAlign: 'top',
    }}
  />
);

export const Tick = () => (
  <TickRaw
    width={28}
    height={28}
    sx={{
      fill: 'currentColor',
      verticalAlign: 'top',
    }}
  />
);

export const TickOutline = () => (
  <TickOutlineRaw
    width={40}
    height={40}
    sx={{
      color: 'secondary',
      verticalAlign: 'top',
    }}
  />
);

export const TickCircle = () => (
  <TickCircleRaw
    width='1.5rem'
    height='1.5rem'
    sx={{
      color: 'currentColor',
      verticalAlign: 'top',
    }}
  />
);

export const AlertCircle = () => (
  <AlertCircleRaw
    width='1.5rem'
    height='1.5rem'
    sx={{
      color: 'currentColor',
      verticalAlign: 'top',
    }}
  />
);

interface AlertOutlineProps {
  mt?: string | number;
  tone?: Tone;
}

export const AlertOutline = ({ mt, tone = 'negative' }: AlertOutlineProps) => (
  <AlertOutlineRaw
    width={40}
    height={40}
    sx={{
      marginTop: mt,
      color: `tone.${tone}`,
      verticalAlign: 'top',
    }}
  />
);

export const LaunchCircle = () => (
  <LaunchCircleRaw
    width={28}
    height={28}
    sx={{
      verticalAlign: 'top',
      marginLeft: '3',
    }}
  />
);

export const DownloadCircle = () => (
  <DownloadCircleRaw
    width={28}
    height={28}
    sx={{
      verticalAlign: 'top',
      marginLeft: '3',
    }}
  />
);

export const SendCircle = () => (
  <SendCircleRaw
    width={28}
    height={28}
    sx={{
      verticalAlign: 'top',
      marginLeft: '3',
    }}
  />
);

export const PrintCircle = () => (
  <PrintCircleRaw
    width={28}
    height={28}
    sx={{
      verticalAlign: 'top',
      marginLeft: '3',
    }}
  />
);

export const Clock = () => (
  <ClockRaw
    width={40}
    height={40}
    sx={{
      color: 'secondary',
      verticalAlign: 'top',
    }}
  />
);

export const Padlock = () => (
  <PadlockRaw
    width={40}
    height={40}
    sx={{
      color: 'secondary',
      verticalAlign: 'top',
    }}
  />
);

export const Five = () => (
  <FiveRaw
    width={40}
    height={40}
    sx={{
      color: 'secondary',
      verticalAlign: 'top',
    }}
  />
);

interface SoftSearchWithTickProps {
  ml?: string | number;
}

export const SoftSearchWithTick = ({ ml = '4' }: SoftSearchWithTickProps) => (
  <SoftSearchWithTickRaw
    width={28}
    height={28}
    sx={{
      fill: 'currentColor',
      verticalAlign: 'top',
      marginLeft: ml,
    }}
  />
);

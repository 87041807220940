import { alpha } from '@theme-ui/color';
import { Theme } from 'theme-ui';

const makeTheme = <T extends Theme>(t: T) => t;

const pxToRem = (sizes: number[]) => sizes.map((size) => `${size / 16}rem`);

export const novunaTheme = makeTheme({
  breakpoints: pxToRem([480, 640, 960, 1200]),
  sizes: {
    container: 1316,
    modal: {
      headingFontSize: {
        small: '3',
        medium: '4',
        large: '4',
      },
      width: {
        small: '343px',
        medium: '606px',
        large: '90vw',
      },
      maxWidth: '1316px',
      minHeight: '10em',
      maxHeight: '80vh',
    },
  },
  colors: {
    text: '#333',
    background: '#f1f0f2',
    primaryPurple: '#7d3cff',
    // primary green
    primary: '#71dc99',
    secondaryPurple: '#3d0061',
    secondaryDarkBlue: '#00005a',
    secondaryLightBlue: '#bbd4ff',
    secondaryDarkGreen: '#1f3b2b',
    secondaryLightGreen: '#d7ffd7',
    secondaryDarkMagenta: '#c60073',
    secondaryLightMagenta: '#ea91ff',
    secondaryMediumPink: '#ff6681',
    secondaryMediumOrange: '#fca674',
    secondaryLightOrange: '#ffd2b6',
    secondaryMediumYellow: '#cfcc73',
    secondaryLightYellow: '#faf26e',
    invalidRed: '#cf4444',
    tints: [
      // Darken
      'rgba(0, 0, 0, 0.7)', // 0
      'rgba(0, 0, 0, 0.3)', // 1
      'rgba(0, 0, 0, 0.1)', // 2
      // Lighten
      'rgba(255, 255, 255, 0.7)', // 3
      'rgba(255, 255, 255, 0.3)', // 4
      'rgba(255, 255, 255, 0.1)', // 5
    ],
    monochrome: [
      '#000000', // 0
      '#333333', // 1
      '#464646', // 2
      '#757575', // 3
      '#bebebe', // 4
      '#dcdcdc', // 5
      '#fafafa', // 6
      '#ffffff', // 7
    ],
    tone: {
      neutral: '#999',
      positive: '#88cfa3',
      negative: '#cf4444',
      background: {
        neutral: 'white',
        positive: '#f9fcfa',
        negative: '#fdf7f7',
      },
    },
    form: {
      input: {
        disabled: {
          background: 'rgba(0, 0, 0, 0.05)',
        },
      },
    },
  },
  fontSizes: pxToRem([
    12, // size 0
    16, // 1
    16, // 2
    18, // 3
    25, // 4
    30, // 5
  ]),
  fontWeights: {
    body: 400,
    heading: 600,
    bold: 700,
  },
  fonts: {
    body: 'Arial, sans-serif',
    heading: '"Silka", Arial, sans-serif',
  },
  lineHeights: {
    body: 1.5,
    heading: 1.5,
  },
  letterSpacings: {
    body: 'normal',
  },
  radii: {
    standard: '5px',
    button: '30px',
    panel: '5px',
  },
  space: [
    // in px
    0, // size 0
    4, // 1
    8, // 2
    16, // 3
    24, // 4
    32, // 5
    40, // 6
  ],
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
      letterSpacing: 'body',
      fontSize: 1,
    },
    h1: {
      color: 'secondaryPurple',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 5,
    },
    h2: {
      color: 'secondaryPurple',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 4,
    },
    h3: {
      color: 'secondaryPurple',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 3,
    },
    h4: {
      color: 'secondaryPurple',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 2,
    },
    h5: {
      color: 'secondaryPurple',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 1,
    },
    h6: {
      color: 'secondaryPurple',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 0,
    },
    img: {
      maxWidth: '100%',
      height: 'auto',
    },
    a: {
      color: 'secondaryPurple',
      textDecoration: 'underline',
      '&:hover': {
        color: 'monochrome.0',
      },
      '&:active': {
        color: 'primaryPurple',
      },
    },
    p: {
      color: 'text',
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
    },
    table: {
      width: '100%',
      borderCollapse: 'collapse',
      borderSpacing: 0,
    },
    tr: {
      '&:nth-child(even)': {
        backgroundColor: 'rgba(0, 0, 0, 0.03)',
      },
    },
    td: {
      padding: 2,
      verticalAlign: 'top',
    },
  },
  button: {
    minWidth: ['100%', '200px'],
    minHeight: 'none',
    verticalAlign: 'middle',
    border: '4px solid',
    borderRadius: 'button',
    fontSize: 1,
    fontFamily: 'heading',
    fontWeight: 'heading',
    lineHeight: 'body',
    boxShadow: 'none',
    cursor: 'pointer',
    outline: 'none',
    '&:disabled': {
      opacity: 0.4,
      cursor: 'default',
    },
    '&:focus': {
      outline: '2px solid black',
    },
  },
  forms: {
    radio: {
      appearance: 'none',
      margin: 0,
      display: 'inline-block',
      width: '24px',
      height: '24px',
      padding: '4px',
      backgroundClip: 'content-box',
      border: '2px solid #999',
      backgroundColor: 'monochrome.7',
      borderRadius: '50%',
      '&:disabled': {
        borderColor: 'monochrome.5',
        backgroundColor: 'transparent',
      },
      '&:checked': {
        backgroundColor: 'monochrome.1',
      },
      '&:focus': {
        outline: '2px solid black',
      },
    },
    input: {
      height: '48px',
      maxWidth: '620px',
      borderWidth: '2px',
      borderRadius: 'standard',
      fontFamily: 'body',
      fontSize: 1,
      outline: 'none',
      padding: '12px',
      backgroundColor: 'white',
      '&:focus': {
        borderColor: 'monochrome.1',
        backgroundColor: '#fcfdff',
        boxShadow: 'inset 0 0 0 2px',
      },
      '::placeholder': {
        color: 'monochrome.3',
      },
      '&:disabled': {
        backgroundColor: 'form.input.disabled.background',
      },
    },
    prefix: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      left: '3',
      fontSize: 1,
    },
    select: {
      borderWidth: '2px',
      borderRadius: 'standard',
      '&:disabled': {
        backgroundColor: 'form.input.disabled.background',
      },
      'option[value=""]:first-child': {
        color: 'inherit',
      },
      'option:not(:first-child)': {
        color: 'inherit',
      },
    },
    textarea: {
      borderWidth: '2px',
      borderRadius: 'standard',
      fontFamily: 'body',
      fontSize: 1,
      outline: 'none',
      padding: '12px',
      backgroundColor: 'white',
      '&:focus': {
        borderColor: 'monochrome.1',
        backgroundColor: '#fbfdff',
        boxShadow: 'inset 0 0 0 2px',
      },
      '::placeholder': {
        color: 'monochrome.3',
      },
      '&:disabled': {
        backgroundColor: 'form.input.disabled.background',
      },
    },
    checkbox: {
      position: 'absolute',
      left: '-9999px',
    },
  },
  checkbox: {
    box: {
      width: '24px',
      height: '24px',
      backgroundColor: 'white',
      border: '2px solid',
      borderColor: 'tone.neutral',
      borderRadius: 'standard',
    },
  },
  message: {
    info: {
      borderColor: '#a4beea',
      backgroundColor: 'rgba(187, 212, 255, 0.24)',
    },
    success: {
      borderColor: '#88cfa3',
      backgroundColor: 'rgba(215, 255, 215, 0.6)',
    },
    warning: {
      borderColor: '#c0bd6a',
      backgroundColor: 'rgba(250, 242, 110, 0.3)',
    },
    error: {
      borderColor: '#e09393',
      backgroundColor: 'rgba(218, 94, 94, 0.1)',
    },
  },
  spinner: {
    color: 'primaryPurple',
  },
  layout: {
    header: {
      creditMaster3: {
        backgroundColor: 'monochrome.7',
        fontFamily: 'Silka',
      },
      payByFinance: {
        backgroundColor: 'primaryPurple',
      },
      personalFinance: {
        backgroundColor: 'primaryPurple',
      },
    },
    footer: {
      maxWidth: 'none',
      color: 'white',
      backgroundColor: 'monochrome.2',
      a: {
        color: 'white',
        textDecoration: 'none',
      },
      hr: {
        color: 'tints.4',
      },
    },
  },
  buttons: {
    // TODO: look into sizing
    close: {
      color: 'secondaryPurple',
    },
    primary: {
      color: 'monochrome.1',
      borderColor: 'primary',
      backgroundImage: (t) => `
          linear-gradient(
              to bottom,
              ${alpha('#71dc99', 1)(t)},
              ${alpha('#2db587', 1)(t)}
          )
      `,
      '&:hover': {
        color: 'monochrome.7',
        borderColor: '#55a573',
        backgroundImage: (t) => `
          linear-gradient(
              to bottom,
              ${alpha('#55a573', 1)(t)},
              ${alpha('#228865', 1)(t)}
          )
        `,
      },
      '&:disabled, &.disabled': {
        color: 'monochrome.1',
        borderColor: 'primary',
        backgroundImage: (t) => `
          linear-gradient(
              to bottom,
              ${alpha('#71dc99', 1)(t)},
              ${alpha('#2db587', 1)(t)}
          )
        `,
      },
      '&:active': {
        borderColor: '#55a573',
        backgroundImage: (t) => `
          linear-gradient(
              to bottom,
              ${alpha('#3b7350', 1)(t)},
              ${alpha('#175e46', 1)(t)}
          )
        `,
      },
    },
    primaryPurple: {
      color: 'monochrome.7',
      borderColor: 'secondaryPurple',
      backgroundColor: 'secondaryPurple',
      '&:hover': {
        color: 'monochrome.7',
        backgroundColor: '#617798',
        borderColor: '#0059ce',
      },
      '&:disabled, &.disabled': {
        backgroundColor: 'secondaryPurple',
        borderColor: 'secondaryPurple',
      },
      '&:active': {
        backgroundColor: 'tints.1',
      },
    },
    secondary: {
      color: 'monochrome.1',
      backgroundColor: '#fff',
      borderColor: 'primary',
      '&:hover': {
        borderColor: '#33b989',
      },
      '&:disabled, &.disabled': {
        borderColor: 'primary',
      },
      '&:active': {
        backgroundColor: 'rgba(51, 185, 137, 0.1)',
      },
    },
    secondaryPurple: {
      color: 'monochrome.1',
      backgroundColor: '#fff',
      borderColor: 'secondaryPurple',
      '&:hover': {
        borderColor: '#0059ce',
      },
      '&:disabled, &.disabled': {
        borderColor: 'secondaryPurple',
      },
      '&:active': {
        backgroundColor: 'rgba(0, 89, 206, 0.1)',
      },
    },
    secondaryDestructive: {
      color: 'invalidRed',
      backgroundColor: '#fff',
      borderColor: 'invalidRed',
      '&:hover': {
        borderColor: '#b94545',
      },
      '&:disabled, &.disabled': {
        borderColor: 'invalidRed',
      },
      '&:active': {
        backgroundColor: 'rgba(218, 94, 94, 0.1)',
      },
    },
    radio: {
      backgroundColor: 'monochrome.7',
      color: 'monochrome.1',
      border: '2px solid',
      borderColor: '#999',
    },
    radioSelected: {
      backgroundColor: 'secondaryPurple',
      color: 'monochrome.7',
      border: '2px solid',
      borderColor: 'secondaryPurple',
    },
    attached: {
      color: 'monochrome.1',
      backgroundColor: '#fff',
      borderColor: '#999',
      '&:hover': {
        borderColor: 'monochrome.1',
        boxShadow: 'inset 0 0 0 2px',
      },
    },
    clear: {
      backgroundColor: 'transparent',
      cursor: 'pointer',
      color: '#3d0061',
      display: 'block',
      width: '24px',
      height: '24px',
      position: 'absolute',
      zIndex: 1,
      padding: '2px',
    },
    pagination: {
      display: 'flex',
      width: '48px',
      height: '48px',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '24px',
      border: 'solid 2px',
      borderColor: 'tone.neutral',
      backgroundColor: 'monochrome.7',
      color: 'monochrome.1',
      cursor: 'pointer',
      padding: 0,

      '&:hover, &:focus': {
        backgroundColor: 'secondaryPurple',
        color: 'monochrome.7',
      },
    },
    paginationSelected: {
      display: 'flex',
      width: '48px',
      height: '48px',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '24px',
      border: 'solid 2px',
      borderColor: 'secondaryPurple',
      backgroundColor: 'secondaryPurple',
      color: 'monochrome.7',
      cursor: 'default',
      padding: 0,

      '&:hover, &:focus': {
        backgroundColor: 'secondaryPurple',
        color: 'monochrome.7',
      },
    },
    paginationDirection: {
      display: 'flex',
      width: '48px',
      height: '48px',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'inherit',
      color: 'secondaryPurple',
    },
  },
  headings: {
    underline: 'none',
  },
  badges: {
    primary: {
      color: 'secondaryPurple',
      backgroundColor: 'rgba(215, 255, 215, 0.6)',
      height: '22px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  modal: {
    mask: {
      backgroundColor: 'tints.1',
    },
    header: {
      marginBottom: 2,
    },
    panel: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 'modal.maxWidth',
      minHeight: 'modal.minHeight',
      maxHeight: 'modal.maxHeight',
      padding: 4,
      backgroundColor: 'white',
      borderRadius: 'panel',
    },
    close: {
      top: '8px',
      right: '8px',
      height: '48px',
      width: '48px',
      svg: {
        height: '24px',
        width: '24px',
      },
      padding: '12px',
      color: 'primaryPurple',
    },
    content: {
      // Avoid clipping of content when rounding
      padding: '0 0 1px',
    },
  },
  channel: 'NOVUNA',
});

export type NovunaTheme = typeof novunaTheme;
